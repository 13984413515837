import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import type { RootState, AppDispatch } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

/**
 * Get url for naviation within the app. Keeps the current search string.
 * @param path New url without the search string (query parameters)
 * @param isRelative Wheter the given path is relative to the current location (the new url will be location.path + '/' + url)
 */
export const useGetAppUrl = (path: string, isRelative?: boolean) => {
  const location = useLocation()
  return getAppUrl(
    isRelative ? location.pathname + '/' + path : path,
    location.search
  )
}

/**
 * Get url for naviation within the app
 * @param path New url without the search string (query parameters)
 * @param search Search string (query parameters)
 */
export const getAppUrl = (path: string, search: string) => {
  return (
      path 
      // Azure has issues with '.'s in the last segment of the path, which we might use for decimal numbers.
      // '.' is a valid url character so it doesn't get encoded by the react machinery and any encodings passed get stripped away
      // so let's just add / to the end of the path.. 
      + '/'
    )
    .replaceAll('//', '/') 
    + search
}