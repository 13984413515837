import ArchitectureScreen, { getPhaseAndSetup } from "routes/ArchitectureScreen"
import CountryScreen from "routes/CountryScreen"
import IndustryScreen, { ALL_SELECTED } from "routes/IndustryScreen"
import PowerScreen, { getPowerAndRuntimeSelection, getPowerAndRuntimeValues } from "routes/PowerScreen"
import RecommendationScreen from "routes/RecommendationScreen"
import { ISelectorStep } from "./SelectorStep"
import { GetApiArchitecturesApiArg, GetApiArchitecturesApiResponse, GetApiCountriesApiArg, GetApiCountriesApiResponse, GetApiIndustriesApiArg, GetApiIndustriesApiResponse, GetApiProductsApiArg, GetApiProductsApiResponse, gstApi } from 'features/gstApi/gstApi';
import { getLocale } from "features/localization/localizationSlice"

const STEPS : readonly ISelectorStep<any, any>[] = [
  {
    id: 'countries',
    in: () => ({ 
      api: gstApi.endpoints.getApiCountries, 
      args: { locale: getLocale() } 
    }),
    text: (t, value, response) => 
      value 
        ? response!.find(c => c.code === value)?.name 
        : t('generic_label_country'),
    analytics: value => Analytics1('country', value),
    element: (value, response) => <CountryScreen countries={response!} selected={value} />,
  } as ISelectorStep<GetApiCountriesApiResponse, GetApiCountriesApiArg>,

  {
    id: 'industries',
    in: steps => ({ 
      api: gstApi.endpoints.getApiIndustries, 
      args: { countryCode: steps.value('countries'), locale: getLocale() } 
    }),
    text: (t, value, response) => 
      value 
        ? value === ALL_SELECTED 
          ? t('navigation_label_industry_any')
          : response!.find(c => c.id === value)?.name 
        : t('navigation_label_industry'),
    analytics: value => Analytics1('industry', value),
    element: (value, response) => <IndustryScreen industries={response!} selected={value} />,
  } as ISelectorStep<GetApiIndustriesApiResponse, GetApiIndustriesApiArg>,

  {  
    id: 'architectures',
    in: () => ({ 
      api: gstApi.endpoints.getApiArchitectures, 
      args: { locale: getLocale() } 
    }),
    text: (t, value, response) => {
      if (!value)
        return t('navigation_label_architecture')

      const ids = getPhaseAndSetup(value)
      const phase = response!.phases.find(p => p.id === ids.phase)
      const setup = response!.setups.find(s => s.id === ids.setup)

      return phase && setup && phase.name + ' & ' + setup.name
    },
    analytics: value => {
      const { phase, setup } = getPhaseAndSetup(value)
      return Analytics2(
        'phase', phase ?? '',
        'setup', setup ?? ''
      )
    },
    productsLoad: steps => { 
      const industryId = steps.value('industries')
      return { 
        api: gstApi.endpoints.getApiProductsStubs, 
        args: { 
          countryCode: steps.value('countries'), 
          industryId: industryId === ALL_SELECTED ? undefined : industryId,
          locale: getLocale() 
        } 
      } 
    },
    element: (value, response, products) => 
      <ArchitectureScreen products={products!} architectures={response!} selected={value} />,
    productsFilter: (value, products) => {
      const { phase, setup } = getPhaseAndSetup(value)
      return products.filter(p => p.phaseArchitectureId === phase && p.setupArchitectureId === setup)
    } 
  } as ISelectorStep<GetApiArchitecturesApiResponse, GetApiArchitecturesApiArg>,

  {  
    id: 'powers',
    text: (t, value) => {
      if (!value)
        return t('navigation_label_power')

      const { power, runtime } = getPowerAndRuntimeSelection(value)

      if (!power)
        return undefined

      return power.min + '-' + power.max + ' ' + t('navigation_label_power_load_unit')
              + (
                runtime && runtime > 0
                  ? ' & ' + runtime + ' ' + t('navigation_label_power_runtime_unit')
                  : ''
              )
    }, 
    analytics: value => {
      const { power, runtime } = getPowerAndRuntimeSelection(value)
      return Analytics3(
        'power-min', power?.min?.toString() ?? '',
        'power-max', power?.min?.toString() ?? '',
        'runtime', runtime?.toString() ?? ''
      )
    },
    element: (value, response, products) => 
      <PowerScreen 
        products={products!} 
        selected={value} 
      />,    
    productsFilter: (value, products) => {
      const { power, runtime } = getPowerAndRuntimeValues(products, value)      
      return products.filter(p => power.min <= p.ratedPower && p.ratedPower <= power.max && p.batteryRuntime >= runtime)
    } 
  },
  
  {  
    id: 'recommendation',
    in: steps => ({ 
      api: gstApi.endpoints.getApiProducts,
      args: { 
        ids: 
          steps.products('powers')!
            .sort((a, b) => a.ratedPower - b.ratedPower + 10 * (a.batteryRuntime - b.batteryRuntime))  
            .slice(0, 4)
            .map(p => p.id),
        locale: getLocale() 
      } 
    }),
    text: t => t('navigation_label_recommendations'),
    element: (value, response, products, steps) => 
      <RecommendationScreen
        products={response!} 
        countryCode={steps.value('countries')}
      />,
  } as ISelectorStep<GetApiProductsApiResponse, GetApiProductsApiArg>,
]

const ANALYTICS_KEY_PREFIX = 'sel-'

function Analytics1(key: string, value: string) {
  let result: { [name: string]: string } = {
    [ANALYTICS_KEY_PREFIX + key]: value
  }
  return result
}

function Analytics2(key1: string, value1: string, key2: string, value2: string) {
  let result: { [name: string]: string } = {
    [ANALYTICS_KEY_PREFIX + key1]: value1,
    [ANALYTICS_KEY_PREFIX + key2]: value2
  }
  return result
}

function Analytics3(key1: string, value1: string, key2: string, value2: string, key3: string, value3: string) {
  let result: { [name: string]: string } = {
    [ANALYTICS_KEY_PREFIX + key1]: value1,
    [ANALYTICS_KEY_PREFIX + key2]: value2,
    [ANALYTICS_KEY_PREFIX + key3]: value3
  }
  return result
}

export default STEPS