import React from 'react';
import cx from 'classnames';
import {ITopicHighlight} from 'components/TopicHighlight';
import {IUseCaseTiles} from 'components/UseCaseTiles';

interface IExtraContentArea {
  primaryArea?: React.ReactElement<ITopicHighlight | IUseCaseTiles>;
  secondaryArea?: React.ReactElement<IUseCaseTiles>;
  isTight?: boolean;
}

export const ExtraContentArea: React.FunctionComponent<IExtraContentArea> = ({
  primaryArea,
  secondaryArea,
  isTight,
}) => (
  <div
    className={cx(
      'ExtraContentArea flex flex-column flex-row-m flex-row-l',
      isTight ? 'na1' : 'na2 na3-l',
    )}
  >
    {/* Big highlight */}
    <div className={cx(isTight ? 'pa1' : 'pa2 pa3-l', 'w-100 w-50-m w-100-l')}>{primaryArea}</div>

    {/* Small highlights */}
    <div className={cx(isTight ? 'pa1' : 'pa2 pa3-l', 'w-100 w-50-m w-100-l')}>{secondaryArea}</div>
  </div>
);
