export const translate = (translations: ITranslations, key: keyof ITranslations, fixUnits?: boolean) => {
  const text = translations[key]

  if (text) {
    if (fixUnits)
      return text.replace('mm2', 'mm²')
    else 
      return text
  }

  // tslint:disable-next-line:no-console
  console.warn(`No translation found for key "${key}`);
  return key
}

export type Translator = (key: keyof ITranslations) => string

export const getTranslator = (translations: ITranslations) => 
  (key: keyof ITranslations) => translate(translations, key)

export interface ITranslations {
  add_to_home_screen_add_label: string;
  add_to_home_screen_cancel_label: string;
  add_to_home_screen_lede: string;
  architecture_screen_description_lede: string;
  architecture_screen_description_title: string;
  architecture_screen_phases_label: string;
  architecture_screen_setups_label: string;
  architecture_screen_title: string;
  cookie_consent_agree_label: string;
  cookie_consent_learn_more_label: string;
  cookie_consent_lede: string;
  country_screen_lede: string;
  country_screen_select_country_placeholder: string;
  country_screen_title: string;
  generic_copyright_notice: string;
  generic_error_occured_label: string;
  generic_label_country: string;
  generic_label_further_reading: string;
  generic_label_next: string;
  generic_label_previous: string;
  generic_label_select: string;
  generic_label_selected: string;
  generic_label_view_details: string;
  generic_latest_news_label: string;
  generic_ok_label: string;
  generic_site_title: string;
  industry_screen_description_lede: string;
  industry_screen_description_title: string;
  industry_screen_skip_label: string;
  industry_screen_title: string;
  navigation_label_architecture: string;
  navigation_label_industry_any: string;
  navigation_label_industry: string;
  navigation_label_power_load_unit: string;
  navigation_label_power_runtime_unit: string;
  navigation_label_power: string;
  navigation_label_recommendations: string;
  power_screen_description_lede: string;
  power_screen_description_title: string;
  power_screen_load_label: string;
  power_screen_no_products_for_given_load: string;
  power_screen_runtime_label: string;
  power_screen_runtime_missing: string;
  power_screen_runtime_too_high: string;
  power_screen_title: string;
  product_battery_protection_label: string;
  product_bypass_cables_label: string;
  product_dimensions_label: string;
  product_heat_dissipation_label: string;
  product_bypass_input_protection_label: string;
  product_rectifier_input_protection_label: string;
  product_output_cables_label: string;
  product_rated_power_label: string;
  product_rectifier_cables_label: string;
  product_weight_label: string;
  recommendation_screen_technical_info_label: string;
  recommendation_screen_contact_us_footnote: string;
  recommendation_screen_contact_us_label: string;
  recommendation_screen_contact_us_lede: string;
  recommendation_screen_contact_us_title: string;
  recommendation_screen_find_partner_label: string;
  recommendation_screen_find_partner_lede: string;
  recommendation_screen_find_partner_title: string;
  recommendation_screen_product_page_label: string;
  recommendation_screen_product_page_title: string;
  recommendation_screen_protected_document_info: string;
  recommendation_screen_services_title: string;
  recommendation_screen_title: string;
  start_screen_lede: string;
  start_screen_start_label: string;
  start_screen_title: string;
}
