import React from 'react';
import { ITranslations, translate } from 'features/localization/translations';

export type {ITranslations};

export const TranslationsContext = React.createContext<ITranslations>({} as any);

export const Translate = ({ t, fixUnits: useSuperscript } : { t : keyof ITranslations, fixUnits?: boolean }) => 
  <TranslationsContext.Consumer>
    {translations => translate(translations, t, useSuperscript)}
  </TranslationsContext.Consumer>

