import descriptionImage from 'assets/img/architecture-description.jpg'
import React, { useEffect, useState } from 'react';
import { CursorHeading } from 'components/CursorHeading';
import { Translate, } from 'features/localization/TranslationsContext';
import { Loading} from 'components/Loading';
import { ArchitecturesViewModel, ArchitectureViewModel, ArticleViewModel, ProductStubViewModel, useGetApiArticlesQuery } from 'features/gstApi/gstApi';
import { RouteButtons } from 'routes/RouteButtons';
import { ExtraContentArea } from 'components/ExtraContentArea';
import { TopicHighlight } from 'components/TopicHighlight';
import { UseCaseTile, UseCaseTiles } from 'components/UseCaseTiles';
import { RadioButton, RadioButtonContainer } from 'components/RadioButton';
import { InfoButton } from 'components/InfoButton';
import { useAppSelector } from 'app/hooks';

// Should be a character that doesn't appear in the IDs and will not get url escaped
export const VALUE_SEPARATOR = '*'

export const getPhaseAndSetup = (value? : string) => {
  const split = value?.split(VALUE_SEPARATOR)
  return {
    phase: split?.at(0),
    setup: split?.at(1)
  }
}

const ArchitectureScreen = ({ products, architectures, selected }: { products: ProductStubViewModel[], architectures: ArchitecturesViewModel, selected?: string }) => {
  const { locale, translations } = useAppSelector(state => state.localization)

  const phaseAndSetup = getPhaseAndSetup(selected)
  const [selectedPhase, setSelectedPhase] = useState(phaseAndSetup.phase ?? '')
  const [selectedSetup, setSelectedSetup] = useState(phaseAndSetup.setup ?? '')
  const [setups, setSetups] = useState<ArchitectureViewModel[]>([])
  
  const phases = 
    architectures.phases
      .filter(phase => products.some((p => p.phaseArchitectureId === phase.id)))

  useEffect(() => {
    const phaseProducts = 
      products.filter(p => p.phaseArchitectureId === selectedPhase)

    const setups =  
      architectures.setups
        .filter(setup => 
          phaseProducts.some(p => 
            p.setupArchitectureId === setup.id
          )
        )

    setSetups(setups)

    if (setups.length === 1)
      setSelectedSetup(setups[0].id)
    else if (!setups.some(s => s.id === selectedSetup))
      setSelectedSetup('')
      
  }, [selectedPhase])

  const defaultArticles = 
    useGetApiArticlesQuery(
      { type: 'ArchitectureFurtherReading', locale: locale }
    )
  
  const articles = 
    defaultArticles.isFetching
      ? undefined
      : defaultArticles.data;

  return (
    <div className="vs4 ph2 ph3-m ph4-l">
      <CursorHeading kind="h2" cls={['f2']}>
        <Translate t="architecture_screen_title" />
      </CursorHeading>
      
      <div className="flex flex-row na2 na3-l">
        <div className="pa2 pa3-l ml3-m ml3-l">
          <p className="pb1 grey-60">
            <Translate t="architecture_screen_phases_label" />
          </p>
          <RadioButtonContainer
            name="phases"
            value={selectedPhase}
            onChange={setSelectedPhase}
            children={phases.map(toOption)}
          />
        </div>

        {setups.length > 0 && (
          <div className="pa2 pa3-l ml5">
            <p className="pb1 grey-60">
              <Translate t="architecture_screen_setups_label" />
            </p>
            <RadioButtonContainer
              name="setups"
              value={selectedSetup}
              onChange={setSelectedSetup}
              children={setups.map(toOption)}
            />
          </div>
        )}
      </div>

      <RouteButtons value={selectedPhase && selectedSetup && selectedPhase + VALUE_SEPARATOR + selectedSetup}/>

      <ExtraContentArea
        primaryArea={
          <TopicHighlight
            title={<Translate t="architecture_screen_description_title" />}
            imgHref={descriptionImage}
            body={<Translate t="architecture_screen_description_lede" />}
          />
        }
        secondaryArea={
          !articles
          ? <Loading height="h5" extraClassName="mt2" />
          : articles.length > 0 
            ? <UseCaseTiles title={translations.generic_label_further_reading}>
              {
                (articles as ArticleViewModel[]).slice(0, 2).map((article, i) => (
                  <UseCaseTile
                    key={i}
                    imgHref={article.image?.url ?? ''}
                    title={article.title}
                    body={article.description ?? ''}
                    href={article.link}
                  />
                ))
              }
              </UseCaseTiles>
            : <></>
        }
      />
    </div>
  )
}

const toOption = (item: ArchitectureViewModel) =>
  <RadioButton key={item.id} value={item.id}>
    <p className="w-100">
      {item.name}
    </p>
    {item.description &&
      <div className="ml4">
        <InfoButton>
          <div style={{ whiteSpace: 'pre-line', width: '300px' }}>
            {item.description}
          </div>
        </InfoButton>
      </div>
    }
  </RadioButton>

export default ArchitectureScreen
