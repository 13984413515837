import React from 'react';
import { Heading } from '../components/Heading';
import { Cursor } from '../components/Cursor';

const fontSizeClsRegex = /^f\d$/;

function getFontSizeFromCls(cls: string | string[]) {
  if (Array.isArray(cls)) {
    return cls.find(_cls => fontSizeClsRegex.test(_cls));
  }

  return fontSizeClsRegex.test(cls) ? cls : undefined;
}

export const CursorHeading: typeof Heading = ({cls, ...props}) => {
  // FIXME: We need dedicated props for each classname type, for e.g. `fontSize="f1"` `color="red"`, etc
  // That's work on the design system in ../designSystem/designSystem.tsx
  const fontSize = getFontSizeFromCls(cls);
  return (
    <div className="CursorHeading">
      <Cursor fontSize={fontSize as any} extraClassName="nb2" />
      <Heading cls={cls} {...props} />
    </div>
  );
};
