import React, { useState, useEffect } from 'react'
import { Heading } from 'components/Heading'
import { Paragraph } from 'components/Paragraph'
import { Markdown } from 'components/Markdown'
import { Tabs, TabList, Tab, TabPanel } from 'components/Tabs'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetApiNewsQuery } from 'features/gstApi/gstApi'
import licenses from 'assets/licenses-export.json'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

export const InfoTabs = [   
  'versions',
  'licenses',
  'disclaimer'
] as const

export type InfoTab = typeof InfoTabs[number]

export const InfoScreen = () => {
  // Info Screen is not localized
  var locale = "en-US"

  const location = useLocation()
  const news = useGetApiNewsQuery({ locale })
  const navigate = useNavigate()
  
  const [selectedTab, setSelectedTab] = useState(0)
  
  const hash = location.hash
  const appInsights = useAppInsightsContext()
  
  useEffect(() => {
    setSelectedTab(
      InfoTabs.indexOf(
        hash.substring(1) as InfoTab
      )
    )

    appInsights.trackPageView({
      uri: location.pathname, 
      name: 'info' + hash
    });
  }, [hash])

  return (
    <div className="InfoScreen">
      <Tabs
        selectedIndex={selectedTab}
        onSelect={index => navigate('#' + InfoTabs[index], { replace: true })}
      >
        <TabList>
          <Tab>Version history</Tab>
          <Tab>Software licenses</Tab>
          <Tab>Disclaimer</Tab>
        </TabList>
        <TabPanel>
          <div className="ph2 ph3-m ph4-l pv3 vs4">
            {news.data
              && news.data.map(newsData => (
                <div key={newsData.id}>
                  <Heading cls="f3">
                    {new Date(newsData.date).toLocaleDateString(locale)} - v{newsData.version}
                  </Heading>
                  <Markdown>{newsData.text}</Markdown>
                </div>
              ))
            }
          </div>
        </TabPanel>
        <TabPanel>
          <div className="ph2 ph3-m ph4-l vs4 pv3">
            {licenses.map((license, index) => (
              <div key={index}>
                <Heading kind="h3" cls="f3">
                  {license.title}
                </Heading>
                <Markdown>{license.content}</Markdown>
              </div>
            ))}
            <div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="ph2 ph3-m ph4-l pv3">
            <Heading cls="f3" kind="h3">
              Terms
            </Heading>

            <Paragraph cls="f4">
              Use of the ABB UPS Selector software (hereinafter referred to as
              “Software”) is subject to the following Terms. ABB reserves itself the right to update
              these Terms at any time without notice to you. To use Software you must accept these
              terms. If you do not accept these terms and this Agreement, do not use the Software.
            </Paragraph>

            <Heading cls="f3" kind="h3">
              Protected Software
            </Heading>

            <Paragraph cls="f4">
              ABB own exclusively all the intellectual property rights to the Software, including
              but not limited to copyright to Software. Software is made available solely according
              to these Terms. Any unauthorized copying of the user interface or procedures or data
              of this software or parts of it is expressly prohibited by law and may result in civil
              and criminal penalties and/or damage claims. You may not reverse engineer, de-compile,
              or disassemble the Software. ABB grants you the limited, non-exclusive,
              non-transferable, non-sublicensable right to use the Software. You’re right to use the
              Software shall expire at the moment you stop using the Software.
            </Paragraph>

            <Heading cls="f3" kind="h3">
              DISCLAIMER
            </Heading>

            <Paragraph cls="f4">
              ABB MAKES AND GIVES NO WARRANTY OF ANY KIND TO THE SOFTWARE. ABB IS NOT LIABLE FOR ANY
              DEFECTS OR SHORTCOMINGS AND THE SOFTWARE IS DELIVERED AS-IS. ABB OY HEREBY DISCLAIMS
              ALL WARRANTIES AND LIABILITIES WITH REGARD TO THE SOFTWARE, INCLUDING ALL WARRANTIES
              OR MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR
              PURPOSE, TITLE AND NON-INFRINGEMENT. ABB OY DOES NOT MAKE ANY ASSURANCES WITH REGARD
              TO THE ACCURACY OF THE SELECTIONS, INFORMATION OR ANY OTHER OUTPUT DERIVING FROM THE
              USE OF SOFTWARE, INCLUDING BUT NOT LIMITED TO THE CONTENTS OF THE INFORMATION OR
              REPORTS MADE BY USING THE SOFTWARE. YOU UNDERSTANDS AND ACCEPTS THAT SPECIFICATIONS OR
              CHARACTERISTICS OF UPS EQUIPMENT HAVE TO BE EVALUATED CASE BY CASE BY USING EXPERT
              JUDGMENT AND PROFESSIONAL VIEWS. SOFTWARE IS ONLY INTENDED TO SUPPORT THE DECISION
              MAKING AND THEREFORE ABB TAKES NO RESPONSIBILITY OR LIABILITY FROM ANY DECISIONS,
              ACTIONS OR OMISSIONS WHICH RESULT FROM THE INFORMATION OR RESULTS DELIVERED TO YOU
              UNDER THIS AGREEMENT OR THROUGH SOFTWARE. WHATEVER, DECISIONS, ACTIONS OR INACTIONS
              YOU MAY DECIDE BASED ON THE INFORMATION RECEIVED FROM THE SOFTWARE, ARE UNDER YOU’RE
              EXCLUSIVE RESPONSIBILITY AND LIABILITY. IN NO EVENT SHALL ABB OY BE LIABLE UNDER THIS
              AGREEMENT FOR ANY DIRECT, PUNITATIVE, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR
              LOSSES WHATSOEVER INDLUCING BUT NOT LIMITED TO LOSS OF USE, LOSS OF DATA, LOSS OF
              INFORMATION, LOSS OF CAPITAL, LOSS OF SAVINGS, LOSS OF PROFITS OR LOSS OF REVENUE,
              WHETHER ARISING IN CONTRACT, TORT (INCLUDING NEGLIGENCE WHETHER ACTIVE OR PASSIVE),
              WARRANTY, STRICT LIABILITY OR OTHERWISE.
            </Paragraph>

            <Heading cls="f3" kind="h3">
              General
            </Heading>

            <Paragraph cls="f4">
              This Agreement shall be governed and interpreted according to the laws of Switzerland. Any
              dispute regarding the existence of this Agreement, any terms under it or other
              differences of opinion between the Parties shall be resolved in the court of Lausanne,
              Switzerland. Both parties agree to waive any other legal venue and proceedings. By
              accessing ABB’s website you also agree to accept ABB’s ”Terms and Conditions of Use”
              which you can find at following link --{' '}
              <a
                href="https://new.abb.com/provider-information"
                target="_blank"
                className="abb-link"
                rel="noopener noreferrer"
              >
                https://new.abb.com/provider-information
              </a>
              .
            </Paragraph>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default InfoScreen
