import { ApplicationInsights, IConfig, IConfiguration } from '@microsoft/applicationinsights-web'
import { ReactPlugin, useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { getLocale } from "features/localization/localizationSlice"

export const SELECTOR_STEP_PAGE_TYPE = 'SelectorStep'

const aiCookies : { [key: string]: string } = {}
const aiReactPlugin = new ReactPlugin()

let config : IConfiguration & IConfig = {
  extensions: [aiReactPlugin],
  connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION,
  // AutoRouteTracking uses site title as the page name and we just keep the same title for every page
  enableAutoRouteTracking: false,
  autoTrackPageVisitTime: true,
  // Disable storage so we don't do anything that would require user consent
  isStorageUseDisabled: true,
  cookieCfg: {
    // We don't actually use cookies or track the user between sessions
    setCookie: (name, value) => aiCookies[name] = value,
    getCookie: name => aiCookies[name]
  },
  loggingLevelTelemetry: 2,
  loggingLevelConsole: 0,

  // DEBUG SETTINGS:
  //enableDebug: true,
  //loggingLevelConsole: 2,
}

if (!process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION) {
  config = { 
    ...config, 
    disableInstrumentationKeyValidation: true,
    disableTelemetry: true,
    // Throws error if instrumentation key is not set
    instrumentationKey: ''
  }
}

if (process.env.REACT_APP_IMMEDIATE_ANALYTICS === "TRUE") {
  config = { 
    ...config, 
    maxBatchInterval: 0,
    maxBatchSizeInBytes: 0,
  }
}

const appInsights = new ApplicationInsights({config})
appInsights.loadAppInsights()

appInsights.addTelemetryInitializer(event => {
    event.data = { 
      ...event.data, 
      locale: getLocale()
    }
});

function usePageAnalytics(name: string) {
  const path = useLocation().pathname  
  const appInsights = useAppInsightsContext()

  useEffect(() => {
    appInsights.trackPageView({uri: path, name});
  }, [path]);
}

export { aiReactPlugin, usePageAnalytics }