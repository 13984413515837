import React from 'react';
import {Heading} from 'components/Heading';
import {Paragraph} from 'components/Paragraph';
import {Image} from 'components/Image';

export interface ITopicHighlight {
  title: string | React.ReactNode;
  imgHref?: string;
  body: string | React.ReactNode;
}

export const TopicHighlight: React.FunctionComponent<ITopicHighlight> = ({
  title,
  imgHref,
  body,
}) => (
  <div className="TopicHighlight nt3">
    <Heading kind="h3" cls={['f3']}>
      {title}
    </Heading>
    <Image src={imgHref} className="w-100 bg-blue-10" aspectRatio="3x1" />
    <Paragraph cls={['grey-90']}>{body}</Paragraph>
  </div>
);
