import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { gstApi } from '../features/gstApi/gstApi'
import { selectorSlice } from 'features/selector/selectorSlice'
import { localizationSlice } from 'features/localization/localizationSlice';

export const store = configureStore({
  reducer: {
    localization: localizationSlice.reducer,
    selector: selectorSlice.reducer,
    api: gstApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(gstApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
