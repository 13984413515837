import './ErrorLabel.scss';
import React from 'react';
import {Icon} from '@abb/abb-common-ux-react/components/Icon';

interface IErrorLabel {
  sizeClass?: 'small' | 'medium' | 'large';
}

export const ErrorLabel: React.FC<IErrorLabel> = ({sizeClass = 'small', children}) => (
  <div className="ErrorLabel">
    <span className="ErrorLabel-Icon">
      <Icon color="color-status-red" name="abb/error-circle-1" sizeClass={sizeClass} />
    </span>
    <span className="ErrorLabel-Message">{children}</span>
  </div>
);
