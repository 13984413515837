import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITranslations } from 'features/localization/translations';
import { parse as qsParse } from 'query-string';
import { RootState } from 'app/store';
import { gstApi } from 'features/gstApi/gstApi';

export const QUERY_PARAMETER = 'lang'

export const Locales = [   
  'en-US',
  'fr-FR',
  'de-DE',
  'es-ES',
  'it-IT'
] as const

export type Locale = typeof Locales[number]

function isLocale(s: string | undefined): s is Locale {
  return Locales.includes(s as Locale)
}

export type Status = 'ok' | 'loading' | 'error' | 'uninitialized'

export interface LocalizationState {
  locale: Locale
  translations: ITranslations,
  status: Status
}

// For easy, non-binding access to locale. The initial value is used as the default locale if preferred locale could not be determined from query string, cookie, or browser.
var _locale : Locale = 'en-US'
export const getLocale = () => _locale

const initialState: LocalizationState = {
  locale: _locale,
  translations: {} as ITranslations,
  status: 'uninitialized'
};

export const localizationSlice = createSlice({
  name: 'localization',
  initialState,
  reducers: { },  
  extraReducers: (builder) => {
    builder
      .addCase(update.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(update.fulfilled, (state, action) => {
        const pl = action.payload
        const newState = pl as NewState 
        
        // Any NewState value or 'true' means success
        state.status = pl ? 'ok' : 'error'

        // Check that we are infact dealing with a NewState
        if (newState.locale && newState.translations) {
          state.locale = newState.locale
          state.translations = newState.translations
        }

        _locale = state.locale
      });
  },
});

type NewState = { locale: Locale, translations: ITranslations }


export const update = createAsyncThunk(
  'localization/update',
  async (queryString: string, { getState, dispatch }) : Promise<NewState | boolean> => {
    const rootState = getState() as RootState
    const state = rootState.localization
    
    let locale: Locale | undefined

    // Use local from query string
    let fromQuery = getLocaleFromQueryString(queryString);
    if (isLocale(fromQuery))
      locale = fromQuery

    // Or get browser preference
    if (!locale) {
      locale = 
        navigator
          .languages
          .map(nl => 
            // navigator.languages can contain locales and languages, so also check for language match with 'startsWith'
            Locales.find(sl => sl.startsWith(nl))
          )
          .filter(v => v !== undefined)
          .at(0)
    }

    locale = locale ?? state.locale
    const localeChanged = locale !== state.locale
    
    // _locale is needed for the api requests that might be made while the static texts request is loading so we set it here already.
    // It's not ideal, but we don't want to set the store locale, before we've actually succesfully loaded the texts. Could also add 'state.requestedLocale'.
    _locale = locale

    let translations : ITranslations | undefined
    if (localeChanged || state.translations === initialState.translations) {
      const { data = [] } = await dispatch(gstApi.endpoints.getApiStaticTexts.initiate({ locale: locale }))
      translations = data as unknown as ITranslations
    }

    return translations
            ? { locale: locale, translations: translations } 
            // true for success if locale was not changed, false for error if locale was changed but translations weren't successfully loaded  
            : !localeChanged
  },
  {
    // Only actually run the thunk if the query string contains the query parameter or the slice is uninitialized
    condition: (arg, { getState } ) => 
      arg.includes(QUERY_PARAMETER) 
      || (getState() as RootState).localization.status === 'uninitialized'
  }
)

function getLocaleFromQueryString(queryString: string) {
  const fromQuery = qsParse(queryString)[QUERY_PARAMETER];
  if (fromQuery)
    return Array.isArray(fromQuery) ? (fromQuery[0] ?? undefined) : fromQuery;
}

export const selectLocale = (state: RootState) => state.localization.locale
export const selectTranslations = (state: RootState) => state.localization.translations