import unselectedImage from 'assets/img/ind-unselected-description.jpg'
import React, { useState } from 'react';
import { CategorySelection, ICategory } from 'components/CategorySelection';
import { CursorHeading } from 'components/CursorHeading';
import { Translate } from 'features/localization/TranslationsContext';
import { Loading } from 'components/Loading';
import { IndustryViewModel, useGetApiArticlesQuery } from 'features/gstApi/gstApi';
import { ForwardButton, RouteButtons } from 'routes/RouteButtons';
import { ExtraContentArea } from 'components/ExtraContentArea';
import { TopicHighlight } from 'components/TopicHighlight';
import { UseCaseTile, UseCaseTiles } from 'components/UseCaseTiles';
import { useAppSelector } from 'app/hooks';

export const ALL_SELECTED = '-'

const IndustryScreen = ({ industries, selected }: { industries: IndustryViewModel[], selected?: string }) => {
  const { locale, translations } = useAppSelector(state => state.localization)
  const [selectedId, setSelectedId] = useState(!selected || selected === ALL_SELECTED ? undefined : selected)
  
  let selectedIndex
    = selectedId ? industries.findIndex(i => i.id === selectedId) : undefined
  
  if (selectedIndex && selectedIndex < 0)
    selectedIndex = undefined
    
  const selectedIndustry
    = selectedIndex !== undefined ? industries[selectedIndex] : undefined
    
  const defaultArticles = 
  useGetApiArticlesQuery(
    { type: "IndustriesFurtherReading", locale: locale }
  )

  const articles = selectedIndustry?.articles ?? defaultArticles.data;

  return (
    <div className="vs4 ph2 ph3-m ph4-l">
      <CursorHeading kind="h2" cls={['f2']}>
        <Translate t="industry_screen_title" />
      </CursorHeading>

      <div className='mw7 center'>
        <CategorySelection
          categories={industries?.map(toCategory)}
          selectedIndex={selectedIndex}
          onSelect={index => setSelectedId(industries[index].id)}
        />
      </div>
      
      <div>
        <RouteButtons value={selectedId}/>
        <ForwardButton 
          className="pa2 na2 flex flex-row justify-end" 
          value={ALL_SELECTED} 
          kind='discreet-black-small' 
          width='1' 
          label='industry_screen_skip_label'
        />
      </div>
      
      <ExtraContentArea
        primaryArea={
          <TopicHighlight
            title={selectedIndustry?.title || <Translate t="industry_screen_description_title" />}
            imgHref={selectedIndustry?.image?.url ?? unselectedImage }
            body={selectedIndustry?.description || <Translate t="industry_screen_description_lede" />}
          />
        }
        secondaryArea={
          articles === undefined
          ? <Loading height="h5" extraClassName="mt2" />
          : articles.length > 0 
            ? <UseCaseTiles title={translations.generic_label_further_reading}>
              {
                articles.slice(0, 2).map((article, i) => (
                  <UseCaseTile
                    key={i}
                    imgHref={article.image?.url ?? ''}
                    title={article.title}
                    body={article.description ?? ''}
                    href={article.link}
                  />
                ))
              }
              </UseCaseTiles>
            : <></>
        }
      />
    </div>
  )
}

function toCategory(item: IndustryViewModel) : ICategory {
  return { title: item.name, iconUrl: 'https:' + item.icon!.url } as ICategory
}
export default IndustryScreen
