import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SELECTOR_PATH } from 'App';
import { ProductStubViewModel, ProductViewModel } from 'features/gstApi/gstApi';
import { RootState } from 'app/store';
import { ISelectorStep, StepId, ApiRequestBuilder } from './SelectorStep';
import STEPS from './Steps';
import { StepStatesMap } from './StepStatesMap';
import { getTranslator, ITranslations } from 'features/localization/translations';

export interface SelectorStepState {
  value: string
  text: string
  products?: ProductStubViewModel[]
}

export interface SelectorStepUpdate extends SelectorStepState {
  step: number; 
}

export interface SelectorState {
  active: number
  steps: SelectorStepState[]
  status: Status
  tag: any
  currentProducts?: ProductStubViewModel[]
  inResponse?: any
}

export type Status = 'ok' | 'loading' | 'error'

const initialState: SelectorState = {
  active: 0,
  steps: [],
  status: 'ok',
  tag: undefined
};

export const selectorSlice = createSlice({
  name: 'selector',
  initialState,
  reducers: {
    initialize: (state, action: PayloadAction<number>) => {
      state.active = 0
      state.steps = new Array(action.payload)
    },
    setStep: (state, action: PayloadAction<SelectorStepUpdate>) => {
      const pl = action.payload
      state.steps[pl.step] = { 
        value: pl.value, 
        text: pl.text, 
        products: pl.products
      }
    },
    setActive: (state, action: PayloadAction<number>) => {
      state.active = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(update.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(update.fulfilled, (state, action) => {
        const pl = action.payload
        state.active = pl.active
        state.steps = pl.steps
        state.status = pl.status
        state.tag = pl.tag
        state.currentProducts = pl.currentProducts
        state.inResponse = pl.inResponse
      });
  },
});

/**
 * If tag is passed and it's different from the previous passed tag, state for all steps is reconstructed. This can be used to force full update when some parameter that the steps' requests depend on changes. Such as locale.
 */
export const update = createAsyncThunk(
  'selector/update',
  async ({ path, translations, tag } : { path: string, translations: ITranslations, tag?: any }, { getState, dispatch }) : Promise<SelectorState> => {
    const rootState = getState() as RootState
    const { tag: oldTag, steps: oldSteps } = rootState.selector
    const newTag = tag ?? oldTag

    const translator = getTranslator(translations)

    const newSteps = new Array<SelectorStepState>(oldSteps.length)
          
    const initiateRequest = <TResponse, TArg>(request? : ApiRequestBuilder<TResponse, TArg>) => {
      if (request) {
        const { api, args } = request(new StepStatesMap(STEPS, newSteps))
        return dispatch(api.initiate(args))
      }
    }

    const initiateRequests = async <TResponse, TArg>(page : ISelectorStep<TResponse, TArg>) => {
      const inCall = initiateRequest(page.in)
      const prodCall = initiateRequest(page.productsLoad)

      return await Promise.all([inCall, prodCall])
    }
    
    const paths = path.split('/')
    
    const pathValues = 
      paths
        .slice(paths.indexOf(SELECTOR_PATH) + 1)
        .filter(p => p !== '')
    
    const activeStep = pathValues.length
    
    // CONSTRUCT/UPDATE STATE FOR STEPS IF NECESSARY  
    const tagChanged = oldTag !== newTag
    
    // Has a step value changed, so that the possible existing states for all further steps are invalid. Initialize to true if tag has changed to force full refresh.
    for (let i = 0; i < STEPS.length; i++) {
      const oldValue = oldSteps[i]?.value
      const newValue = pathValues.at(i) ?? oldValue
      const valueChanged = oldValue !== newValue
      if (newValue && (tagChanged || valueChanged)) {
        const step = STEPS[i]

        const results = await initiateRequests(step)
        if (results.some(r => r?.isError))
          return { status: 'error', active: i, steps: newSteps, tag: newTag }

        const text = step.text(translator, newValue, results[0]?.data)
        // If text is undefined for a step within the active range, we have a value/data missmatch, so act as if this step wasn't completed yet. This can happen if invalid value was passed as part of an url, or if data has changed since the construction of the url.
        if (text === undefined) {
          console.warn(`.text for step ${i} ${step.id ?? ''} returned undefined for value ${newValue}. Backtracking selector state to the previous step.`)
          return { status: 'error', active: i, steps: newSteps, tag: newTag }
        }

        // Products for a step are either the results of the products query or products from the previous step
        const products = 
          results[1]?.data 
          ?? (i > 0 
            ? newSteps[i - 1].products
            : undefined)

        newSteps[i] = { 
          value: newValue, 
          text: text, 
          products: (products && step.productsFilter?.(newValue, products)) || products
        }

        // If a step value was changed, not added, values for rest of the steps are (possibly) invalid, so skip them
        if (valueChanged && oldValue !== undefined)
          break
      }
      else {
        newSteps[i] = oldSteps[i]
      }
    }

    // LOAD DATA AND PRODUCTS FOR THE ACTIVE STEP
    
    const step = STEPS[activeStep]
    const results = await initiateRequests(step)

    if (results.some(r => r?.isError))
      return { status: 'error', active: activeStep, steps: newSteps, tag: newTag }

    // Current step uses products either from the defined products query, or from the previous step that returned products 
    const products = 
      results[1]?.data as ProductViewModel[]
      ?? (activeStep > 0 
        && newSteps
            .slice(0, activeStep)
            .reverse()
            .find((v, i) => v.products)
            ?.products)

    return { 
      status: 'ok', 
      active: activeStep, 
      steps: newSteps, 
      inResponse: results[0]?.data, 
      currentProducts: products,
      tag: newTag
    }
  }
);

export const { setStep, setActive, initialize } = selectorSlice.actions;

export const selectStatus = (state: RootState) => state.selector.status

export const selectActive = (state: RootState) => state.selector.active

export const selectFurthest = (state: RootState) => {
  const s = state.selector
  const i = s.steps.findIndex(s => s === undefined);

  return (
    i === -1
      ? s.steps.length
      : i)
} 


export const selectValues = (state: RootState) => {
  let values = new Map<StepId, string>()
  
  const steps = state.selector.steps
  for (let index = 0; index < steps.length; index++) {
    const step = steps[index];
    const id = STEPS[index].id
    if (step && id)
      values.set(id, step.value)
  }

  return values 
} 

export const selectStep = (index: number) => (state: RootState) => {
  if (index < 0)
    return undefined

  return state.selector.steps[index]
} 

export const selectSteps = (state: RootState) => state.selector.steps

export const selectStepsMap = (state: RootState) => new StepStatesMap(STEPS, state.selector.steps)

export const selectProducts = (state: RootState) => {
  const s = state.selector

  if (s.active === 0)
    return []

  return s.steps[s.active - 1].products
} 


export default selectorSlice.reducer;
