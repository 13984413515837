import React from 'react';
import {Link} from 'react-router-dom';

export interface IBreadcrumb {
  label: string;
  url?: string;
}

interface IBreadcrumbs {
  breadcrumbs: IBreadcrumb[];
}

export const Breadcrumbs: React.FunctionComponent<IBreadcrumbs> = ({breadcrumbs}) => (
  <>
    {breadcrumbs.map((breadcrumb, index) => (
      <div key={index} className="flex items-center">
        <span className="icon_abb_32 icon-abb_caret-right_32 ph1" />
        {breadcrumb.url ? (
          <Link to={breadcrumb.url} className="link grey-40">
            {breadcrumb.label}
          </Link>
        ) : (
          breadcrumb.label
        )}
      </div>
    ))}
  </>
);
