import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectStep, update } from "features/selector/selectorSlice"
import { StepStatesMap } from "features/selector/StepStatesMap"
import { ISelectorStep } from "features/selector/SelectorStep"
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Loading } from "components/Loading"
import { Translate } from "features/localization/TranslationsContext"
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { SeverityLevel } from "@microsoft/applicationinsights-web"

const SelectorStepHost = ({ steps, index }: { steps: readonly ISelectorStep<any, any>[], index: number }) => {
  const step = steps[index]

  const dispatch = useAppDispatch()
  const path = useLocation().pathname  
  
  const [ dispatching, setDispatching ] = useState(true)
  const { inResponse, currentProducts, status, steps: states } = useAppSelector(state => state.selector)
  const { locale, translations, status: localizationStatus } = useAppSelector(state => state.localization)

  const appInsights = useAppInsightsContext().getAppInsights()
  const analyticsId = step.id ?? index.toString()
  
  useEffect(() => {
    // translations might might not have been loaded if this is the initial load of the page 
    if (localizationStatus === "ok") {
        dispatch(update({ path, translations, tag: locale }))
        setDispatching(false)
    }
  }, [path, locale, translations, localizationStatus])
  
  const isError = status === 'error' || localizationStatus === 'error'
  const isLoading = dispatching || status === 'loading'
  
  useEffect(() => {    
    if (isLoading) {
      appInsights.startTrackPage(analyticsId)
    } else {
      const analytics =
        states
          .map((v, i) => v && steps[i].analytics?.(v.value))
          .reduce((prev, cur) => prev && cur ? Object.assign(prev, cur) : prev ?? cur)
      
      appInsights.stopTrackPage(
        analyticsId, 
        path, 
        analytics
      )
    }
  }, [isLoading])

  const oldSelf = useAppSelector(selectStep(index))

  if (isError) {
    appInsights.trackException({ 
      error: new Error('Error loading step data for ' + analyticsId), 
      severityLevel: SeverityLevel.Error 
    });
    
    return (
      <div className='tc mt6 fw3'>
        <Translate t='generic_error_occured_label' />
      </div>
    )
  }

  if (isLoading)
    return (
      // Give this some height to reduce flashing (scrollbar)
      <div style={{ height: '600px' }}>
        <Loading />
      </div>
    )

  return step.element(oldSelf?.value, inResponse, currentProducts, new StepStatesMap(steps, states))
}

export default SelectorStepHost