import './StartScreen.scss';
import journeySmall from 'assets/img/journey-small.svg'
import journeyLarge from 'assets/img/journey-large.svg'
import { ButtonLink } from 'components/Button';
import { CursorHeading } from 'components/CursorHeading';
import { Paragraph } from 'components/Paragraph';
import { Translate } from 'features/localization/TranslationsContext';

const StartScreen = ({ nextUrl }: { nextUrl: string }) => 
  <div className="StartScreen pa4 pa5-l nt4 nb6 grey-90">
    <div className="w5 w6-s mw-100 pt0 pt4-l">
      <CursorHeading kind="h2" cls="f2">
        <Translate t="start_screen_title" />
      </CursorHeading>
      <Paragraph cls="f4">
        <Translate t="start_screen_lede" />
      </Paragraph>
    </div>

    <div className="ph2 pv5 ph4-s pv0-m">
      <picture>
        <source srcSet={journeyLarge} media="(min-width: 600px)" />
        <img alt='' src={journeySmall} style={{ margin: 'auto', display: 'block', width: '100%' }} />
      </picture>
    </div>

    <div className="tc tr-m flex flex-row-reverse">
      <div className="flex flex-column items-center vs3">
        <p>
          {/* TODO: add `wide` parameter to `ButtonLink` */}
          <ButtonLink kind="primary-red" to={nextUrl}>
            <Translate t="start_screen_start_label" />
          </ButtonLink>
        </p>
      </div>
    </div>
  </div>


export default StartScreen;