import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

export interface IOptionType<ValueType> {
  label: string;
  value: ValueType;
}

export interface IGroupOptionType<ValueType> {
  label: string;
  options: Array<IOptionType<ValueType>>;
}

interface IDropdownProps<ValueType> {
  className?: string;
  disabled?: boolean;
  ignoreGroups?: boolean;
  label?: string;
  onChange?: (selected: ValueType) => any;
  options: Array<IOptionType<ValueType>> | Array<IGroupOptionType<ValueType>>;
  placeholder?: string;
  searchable?: boolean;
  value?: ValueType;
  testId?: string;
}

interface IDropdownState<ValueType> {
  selected: IOptionType<ValueType>;
}

const customStyles = {
  control: (base: any) => ({
    ...base,
    borderRadius: 0,
    border: '1px solid #bababa',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    marginTop: 5,
    textAlign: 'left',
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
  }),
  valueContainer: (base: any) => ({
    ...base,
    lineHeight: 1.5,
  }),
};

class Dropdown<ValueType> extends React.Component<
  IDropdownProps<ValueType>,
  IDropdownState<ValueType>
> {
  static defaultProps: IDropdownProps<any> = {
    ignoreGroups: true,
    options: [],
    searchable: true,
  };

  render() {
    const {
      className,
      disabled,
      ignoreGroups,
      label,
      onChange,
      options,
      placeholder,
      searchable,
      value,
    } = this.props;
    const getProperValue = (
      opts: Array<IOptionType<ValueType>> | Array<IGroupOptionType<ValueType>>,
      val?: ValueType,
      isGroupType?: boolean,
    ) => {
      if (isGroupType) {
        return _.chain(opts as Array<IGroupOptionType<ValueType>>)
          .map(group => group.options)
          .flatten()
          .find(opt => opt.value === val)
          .value();
      }
      return (opts as Array<IOptionType<ValueType>>).find(opt => opt.value === val);
    };

    return (
      <label className={className}>
        <div className="f5 grey-60 pb1" data-test-id={this.props ? this.props.testId : ''}>
          {label}
        </div>
        <Select
          isDisabled={disabled}
          isSearchable={searchable}
          onChange={(selected: any) => {
            if (onChange && selected && !Array.isArray(selected)) {
              onChange(selected.value);
            }
          }}
          onBlur={event => event.preventDefault()}
          options={options}
          placeholder={placeholder}
          styles={customStyles}
          value={getProperValue(options, value, !ignoreGroups) || null}
        />
      </label>
    );
  }
}

export default Dropdown;
