import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiArchitectures: build.query<
      GetApiArchitecturesApiResponse,
      GetApiArchitecturesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/architectures`,
        params: { Locale: queryArg.locale },
      }),
    }),
    getApiArticles: build.query<
      GetApiArticlesApiResponse,
      GetApiArticlesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles`,
        params: { Type: queryArg["type"], Locale: queryArg.locale },
      }),
    }),
    getApiCountries: build.query<
      GetApiCountriesApiResponse,
      GetApiCountriesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/countries`,
        params: { Locale: queryArg.locale },
      }),
    }),
    getApiIndustries: build.query<
      GetApiIndustriesApiResponse,
      GetApiIndustriesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/industries`,
        params: { CountryCode: queryArg.countryCode, Locale: queryArg.locale },
      }),
    }),
    getApiNews: build.query<GetApiNewsApiResponse, GetApiNewsApiArg>({
      query: (queryArg) => ({
        url: `/api/news`,
        params: { Locale: queryArg.locale },
      }),
    }),
    getApiProducts: build.query<
      GetApiProductsApiResponse,
      GetApiProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/products`,
        params: { Ids: queryArg.ids, Locale: queryArg.locale },
      }),
    }),
    getApiProductsStubs: build.query<
      GetApiProductsStubsApiResponse,
      GetApiProductsStubsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/products/stubs`,
        params: {
          CountryCode: queryArg.countryCode,
          IndustryId: queryArg.industryId,
          Locale: queryArg.locale,
        },
      }),
    }),
    getApiStaticTexts: build.query<
      GetApiStaticTextsApiResponse,
      GetApiStaticTextsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/static-texts`,
        params: { Locale: queryArg.locale },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as gstApi };
export type GetApiArchitecturesApiResponse =
  /** status 200 Success */ ArchitecturesViewModel;
export type GetApiArchitecturesApiArg = {
  locale: string;
};
export type GetApiArticlesApiResponse =
  /** status 200 Success */ ArticleViewModel[];
export type GetApiArticlesApiArg = {
  type: ArticleCollectionType;
  locale: string;
};
export type GetApiCountriesApiResponse =
  /** status 200 Success */ CountryViewModel[];
export type GetApiCountriesApiArg = {
  locale: string;
};
export type GetApiIndustriesApiResponse =
  /** status 200 Success */ IndustryViewModel[];
export type GetApiIndustriesApiArg = {
  countryCode: string;
  locale: string;
};
export type GetApiNewsApiResponse = /** status 200 Success */ NewsViewModel[];
export type GetApiNewsApiArg = {
  locale: string;
};
export type GetApiProductsApiResponse =
  /** status 200 Success */ ProductViewModel[];
export type GetApiProductsApiArg = {
  ids: string[];
  locale: string;
};
export type GetApiProductsStubsApiResponse =
  /** status 200 Success */ ProductStubViewModel[];
export type GetApiProductsStubsApiArg = {
  countryCode: string;
  industryId?: string;
  locale: string;
};
export type GetApiStaticTextsApiResponse = /** status 200 Success */ {
  [key: string]: string;
};
export type GetApiStaticTextsApiArg = {
  locale: string;
};
export type ArchitectureViewModel = {
  id: string;
  name: string;
  description?: string | null;
};
export type ArchitecturesViewModel = {
  phases: ArchitectureViewModel[];
  setups: ArchitectureViewModel[];
};
export type Severity = "Error" | "Warning" | "Info";
export type ValidationFailure = {
  propertyName?: string | null;
  errorMessage?: string | null;
  attemptedValue?: any | null;
  customState?: any | null;
  severity?: Severity;
  errorCode?: string | null;
  formattedMessagePlaceholderValues?: {
    [key: string]: any;
  } | null;
};
export type ValidationResult = {
  isValid?: boolean;
  errors?: ValidationFailure[] | null;
  ruleSetsExecuted?: string[] | null;
};
export type AssetViewModel = {
  url: string;
  contentType: string;
  title?: string | null;
};
export type ArticleViewModel = {
  link: string;
  title: string;
  description?: string | null;
  image?: AssetViewModel;
};
export type ArticleCollectionType =
  | "None"
  | "ServiceOffering"
  | "IndustriesFurtherReading"
  | "ArchitectureFurtherReading"
  | "PowerAndRuntimeFurtherReading";
export type CountryViewModel = {
  code: string;
  name: string;
};
export type IndustryViewModel = {
  id: string;
  name: string;
  icon: AssetViewModel;
  title?: string | null;
  description?: string | null;
  image?: AssetViewModel;
  articles?: ArticleViewModel[] | null;
};
export type NewsViewModel = {
  id?: string | null;
  version: string;
  date: string;
  text: string;
};
export type DocumentViewModel = {
  name: string;
  url: string;
  isProtected?: boolean;
};
export type ProductViewModel = {
  id: string;
  phaseArchitectureId: string;
  setupArchitectureId: string;
  ratedPower: number;
  batteryRuntime: number;
  name: string;
  image?: AssetViewModel;
  blockDiagram?: AssetViewModel;
  documents: DocumentViewModel[];
  url?: string | null;
  inputProtectionRectifier?: string | null;
  inputProtectionBypass?: string | null;
  batteryProtection?: string | null;
  rectifierCables?: string | null;
  bypassCables?: string | null;
  outputCables?: string | null;
  ratedActivePower?: number | null;
  weight?: number | null;
  dimensionsWxDxH?: string | null;
  heatDissipation?: number | null;
};
export type ProductStubViewModel = {
  id: string;
  phaseArchitectureId: string;
  setupArchitectureId: string;
  ratedPower: number;
  batteryRuntime: number;
};
export const {
  useGetApiArchitecturesQuery,
  useGetApiArticlesQuery,
  useGetApiCountriesQuery,
  useGetApiIndustriesQuery,
  useGetApiNewsQuery,
  useGetApiProductsQuery,
  useGetApiProductsStubsQuery,
  useGetApiStaticTextsQuery,
} = injectedRtkApi;
