import React from 'react';
import { Heading } from 'components/Heading';
import { Translate } from 'features/localization/TranslationsContext';
import { Paragraph } from 'components/Paragraph';
import { ButtonLink } from 'components/Button';
import { stringify as qsStringify } from 'query-string';
import { Locale } from 'features/localization/localizationSlice';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

interface IContactSection {
  countryCode: string;
  locale: Locale;
}

const CONTACT_CENTER_URL = "https://new.abb.com/contact-centers"
const CONTACT_BASE_URL = 'https://new.abb.com/contact';
const PARTNER_BASE_URL = 'https://new.abb.com/channel-partners/search';
const CH_COUNTRY_CODE = 'CH';
const CH_MAILTO_ADDRESS = 'ups@ch.abb.com';
const US_COUNTRY_CODE = "US";
const US_MAILTO_ADDRESS='patrick.gannon@us.abb.com';

interface IGetContactUrl {
  countryCode: string;
  locale?: Locale;
  productId?: string;
  companyId?: string;
  cidPath?: string;
}

function createMailtoLink(email: string):string{
  return `mailto:${email}?subject=UPS%20Request`;
};
export function getContactUrl(contactForm: IGetContactUrl) {
  const countryCode = contactForm.countryCode;
  if (countryCode === CH_COUNTRY_CODE) return createMailtoLink(CH_MAILTO_ADDRESS);
  if (countryCode === US_COUNTRY_CODE) return createMailtoLink(US_MAILTO_ADDRESS);  
  
  const locale = contactForm.locale
  
  const language = 
    (!locale || locale === 'en-US')
      ? '' 
      : `${locale.toString().split('-')[0]}/`

  const params: any = {country_id: contactForm.countryCode};

  // Assign optional parameters:
  if (contactForm.productId) {
    params.product_id = contactForm.productId;
  }
  if (contactForm.companyId) {
    params.company_id = contactForm.companyId;
  }
  if (contactForm.cidPath) {
    params.cid_path = contactForm.cidPath;
  }

  return `${CONTACT_BASE_URL}/${language}form/#${qsStringify(params)}`;
}

interface IGetPartnerUrl {
  countryCode: string;
  cidPath?: string;
}

export function getPartnerUrl(contactForm: IGetPartnerUrl) {
  const params: any = {country: contactForm.countryCode};

  if (contactForm.cidPath) {
    params.cid_path = contactForm.cidPath;
  }

  return `${PARTNER_BASE_URL}#${qsStringify(params)}`;
}

export const ContactSection: React.FunctionComponent<IContactSection> = ({
  countryCode,
  locale: language,
}) => {
  const analytics = useAppInsightsContext()
  const url = getContactUrl({ countryCode, locale: language });
  return (
      <div className="ContactSection">
      <div className="flex flex-column flex-row-m vs2 tc tl-m">
        <div className="w-100 ph2 ph3-m ph4-l pv3-m pv4-l">
          <Heading kind="h3" cls="f3">
            <Translate t="recommendation_screen_contact_us_title" />
          </Heading>
          <Paragraph cls="f4">
            <Translate t="recommendation_screen_contact_us_lede" />
          </Paragraph>
          <Paragraph cls="f4">
            <ButtonLink 
              kind="primary-red" 
              to={url} 
              isExternal
              onClick={_ => analytics.trackPageView({name: 'contact link', uri: url})}
            >
              <Translate t="recommendation_screen_contact_us_label" />
            </ButtonLink>
          </Paragraph>
        </div>
      </div>

      <div className="ph3-m ph4-l tc tl-m">
        <Translate t="recommendation_screen_contact_us_footnote" />{' '}
        <a
          href={CONTACT_CENTER_URL}
          className="abb-link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={_ => analytics.trackPageView({name: 'contact center link', uri: CONTACT_CENTER_URL})}
        >
          ABB Contact Center
        </a>
      </div>
    </div>
  )
} 
