import React, {useState} from 'react';
import cx from 'classnames';

interface ITooltipContainer {
  extraClassName?: string;
  children: (
    isTooltipOpen: boolean,
    toggleTooltip: () => void,
    setIsTooltipOpen: (isTooltipOpen: boolean) => void,
  ) => React.ReactNode;
}

interface ITooltip {
  isOpen: boolean;
  position?: 'left' | 'right';
  extraClassName?: string;
}

function useToggle(initialValue?: boolean) {
  const [isTrue, setIsTrue] = useState(initialValue);
  return [isTrue, () => setIsTrue(!isTrue), setIsTrue] as [
    boolean,
    () => void,
    (isTrue: boolean) => void,
  ];
}

export const TooltipContainer: React.FunctionComponent<ITooltipContainer> = ({
  extraClassName,
  children,
}) => {
  const [isTooltipOpen, toggleTooltip, setIsTooltipOpen] = useToggle();
  return (
    <div className={cx('TooltipContainer relative', extraClassName)}>
      <div
        className={cx(
          'fixed z-1 top-0 right-0 bottom-0 left-0',
          isTooltipOpen ? 'events-auto' : 'events-none',
        )}
        onClick={ev => {
          ev.preventDefault();
          setIsTooltipOpen(false);
        }}
      />
      {children(isTooltipOpen, toggleTooltip, setIsTooltipOpen)}
    </div>
  );
};

export const Tooltip: React.FunctionComponent<ITooltip> = ({
  isOpen,
  position = 'left',
  extraClassName,
  children,
}) => (
  <div
    className={cx(
      'Tooltip absolute z-1 pa3 ba br1 bg-grey-10 b--grey-60',
      extraClassName,
      !isOpen && 'dn',
      position === 'left' ? 'left-0' : 'right-0',
    )}
  >
    {children}
  </div>
);
