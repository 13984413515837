import React from 'react';
import { SiteLayout } from 'siteLayout/SiteLayout';
import { Outlet, useLocation } from 'react-router-dom';
import { INavigatorItem, Navigator } from 'components/Navigator'
import { selectActive, selectFurthest, selectSteps } from 'features/selector/selectorSlice';
import { getAppUrl, useAppSelector, useGetAppUrl } from 'app/hooks';
import { ISelectorStep } from 'features/selector/SelectorStep';
import { selectTranslations } from 'features/localization/localizationSlice';
import { getTranslator } from 'features/localization/translations';
import { SELECTOR_PATH } from 'App';


export const SelectorLayout = ({ steps }: { steps: readonly ISelectorStep<any, any>[] }) => {
  const states = useAppSelector(selectSteps)
  const furthest = useAppSelector(selectFurthest)
  const active = useAppSelector(selectActive)
  
  const translations = useAppSelector(selectTranslations)
  const translator = getTranslator(translations)
  const location = useLocation()

  return (
    <SiteLayout showLanguageSelector openInfoLinksInNewTab>
      <div className="SelectorLayout vs4 nb4">
        <div className="no-print">
          <div className="SelectorNavigator">
            <Navigator
              items={
                steps.map<INavigatorItem>((page, index) => { 
                  return {
                    label: `${states[index]?.text || page.text(translator)}`,
                    
                    url: 
                      getAppUrl(
                        index <= furthest
                          ? states.reduce<string>((agg, cur, i) => 
                              i < index 
                                ? agg + '/' + cur.value
                                : agg, 
                              '/' + SELECTOR_PATH
                              )
                          : '',
                          location.search
                      ),
                    
                    status: 
                      index === active
                        ? 'active' 
                        : index <= furthest 
                          ? 'visited' 
                          : 'future',
                  } 
                })
              }
            />
          </div>
        </div>
        <Outlet/>
      </div>
    </SiteLayout>
  )
}