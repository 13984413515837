import React from 'react';
import {first, tail} from 'lodash';
// @ts-ignore
import {Tabs as RTabs, TabList as RTabList, Tab as RTab, TabPanel as RTabPanel} from 'react-tabs';

interface ITabs {
  selectedIndex?: number;
  onSelect?: (index: number, lastIndex: number, event: Event) => false | any;
  className?: string;
}

interface ITab {
  disabled?: boolean;
}

// These are just proxies, they are transformed to `react-tabs` components in `Tabs`
export const Tab: React.FunctionComponent<ITab> = () => null;
export const TabPanel: React.FunctionComponent<{}> = () => null;
export const TabList: React.FunctionComponent<{}> = () => null;

export const Tabs: React.FunctionComponent<ITabs> = ({children, ...props}) => {
  const childrenArray = React.Children.toArray(children);
  const list = first(childrenArray);
  const panels = tail(childrenArray);
  return (
    <RTabs {...props} disabledTabClassName="grey-30" selectedTabClassName="bb bw2 b--grey-90">
      {React.Children.map(list, tabList =>
        React.isValidElement(tabList) ? (
          <RTabList {...tabList.props} className="flex bb bw1 b--grey-30">
            {React.Children.map((tabList.props as any).children, tab => {
              const pointerClassName = tab.props.disabled ? 'cursor-default' : 'pointer';
              return (
                React.isValidElement(tab) && (
                  <RTab
                    {...tab.props}
                    className={`pv2 ph3 pv3-m ph4-m f4 f3-m ${pointerClassName}`}
                  />
                )
              );
            })}
          </RTabList>
        ) : (
          tabList
        ),
      )}

      {React.Children.map(panels, tabPanel =>
        React.isValidElement(tabPanel) ? <RTabPanel {...tabPanel.props} /> : tabPanel,
      )}
    </RTabs>
  );
};
