import React from 'react';
import cx from 'classnames';

const aspectRatioPaddings = {
  '1x3': `${300 / 1}%`,
  '1x2': `${200 / 1}%`,
  '2x3': `${300 / 2}%`,
  '1x1': `${100}%`,
  '2x1': `${100 / 2}%`,
  '3x1': `${100 / 3}%`,
  '4x3': `${300 / 4}%`,
  '12x5': `${500 / 12}%`,
};

interface IImage extends React.ImgHTMLAttributes<HTMLImageElement> {
  aspectRatio: keyof typeof aspectRatioPaddings;
  imgClassName?: string;
  children?: never;
}

export const Image: React.FunctionComponent<IImage> = ({
  aspectRatio,
  className,
  imgClassName,
  src,
  ...props
}) => (
  <div
    className={cx('Image relative overflow-hidden', className)}
    style={{paddingBottom: aspectRatioPaddings[aspectRatio]}}
  >
    {src && (
      <img
        key={src}
        className={cx('w-100 absolute top-0 right-0 bottom-0 left-0', imgClassName)}
        src={src}
        {...props}
      />
    )}
  </div>
);
