import './RecommendationScreen.scss';
import React, { useState } from 'react';
import { CursorHeading } from 'components/CursorHeading';
import cx from 'classnames';
import { ITranslations, Translate } from 'features/localization/TranslationsContext';
import { ProductViewModel, useGetApiArticlesQuery } from 'features/gstApi/gstApi';
import { Button } from 'components/Button';
import { ContactSection } from 'components/ContactSection';
import { Loading } from 'components/Loading';
import { UseCaseTile } from 'components/UseCaseTiles';
import { Heading } from 'components/Heading';
import { useAppSelector } from 'app/hooks';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Tooltip, WithTooltip } from '@abb/abb-common-ux-react';

const RecommendationScreen = ({ products, countryCode }: { products: ProductViewModel[], countryCode: string }) => {
  const { locale, translations } = useAppSelector(state => state.localization)
  const [selected, setSelected] = useState(products[0])

  const articles = useGetApiArticlesQuery({ 
    type: 'ServiceOffering', 
    locale: locale 
  })

  return (
    <div className="vs4">
      <div className="ph2 ph3-m ph4-l">
        <CursorHeading kind="h2" cls={['f2']}>
          <Translate t="recommendation_screen_title" />
        </CursorHeading>
      </div>

      <div 
        className="ComparisonTable ph2 ph3-m ph4-l" 
        style={{ gridTemplateColumns: `repeat(${products.length}, 1fr)` }}>
          
        {products.map((product, i) => (
          <>
            {/* Product image */}
            <div className='ph5 flex items-end justify-center'>
              <img 
                src={product.image?.url} 
                alt={product.name} 
                style={{ objectFit: 'scale-down', objectPosition: 'bottom', maxHeight: '200px', minHeight: '100px' }} />
            </div>

            {/* Product name */}
            <div className='w-100 b tc f4'>
              {product.name}
            </div>

            {/* Select buttons */}
            <div className='w-100 tc'>
              <Button
                disabled={selected === product}
                kind='ghost'
                onClick={() => setSelected(product)}
              >
                <Translate t='recommendation_screen_technical_info_label'/>
              </Button>
            </div>

            {/* Documents */}
            <div className={cx(
              'w-100 mv1 bb bw2 bt',
              product.documents && 'pv2',
              product === selected ? 'b--red' : 'b--grey-30',
              )}>
              {product.documents?.map((document, i) =>
                <div key={i} className='pv1'>
                  <DocumentLink
                    href={document.url}
                    iconCls="icon-abb_download_32"
                    label={document.name}
                  />
                  {document.isProtected &&
                    <WithTooltip>
                      <span className="icon_abb_32 ml2 icon-abb_lock-closed_32 grey-60 hover-grey-90" />
                      <Tooltip >
                        <p>{translations.recommendation_screen_protected_document_info}</p>
                      </Tooltip>
                    </WithTooltip>
                  }
                </div>
              )}
            </div>

            {/* Product page link */}
            <div className='w-100'>
              {product.url && (
                <DocumentLink
                  href={product.url}
                  iconCls="icon-abb_document_32"
                  label={translations.recommendation_screen_product_page_label}
                />
              )}
            </div>
          </>
        ))}
      </div>

      {/* Technical info */}
      <div className="ph2 ph3-m ph4-l flex flex-column flex-row-m flex-row-l">
        <div className="w-100 w-50-m w-50-l">
          <Heading kind="h2" cls="f3">
              {selected.name} 
          </Heading>
          <div className="mb2 pr3-m pr3-l flex center-items">
            <img className="w-50 fl DetailsImage" src={selected.image?.url} />
            <img className="w-50 fr DetailsImage" src={selected.blockDiagram?.url} />
          </div>
        </div>
          <div className="w-100 w-50-m w-50-l pl3-m pl3-l">
            <Heading kind="h2" cls="f3">
              <Translate t="recommendation_screen_technical_info_label" />
            </Heading>
            <table className='w-100'>
              <tbody>
                <DetailsRow label='product_rated_power_label' value={(selected.ratedPower ?? '-') + '/' + (selected.ratedActivePower ?? '-')} />
                <DetailsRow label='product_rectifier_input_protection_label' value={selected.inputProtectionRectifier} />
                <DetailsRow label='product_bypass_input_protection_label' value={selected.inputProtectionBypass} />
                <DetailsRow label='product_battery_protection_label' value={selected.batteryProtection} />
                <DetailsRow label='product_rectifier_cables_label' value={selected.rectifierCables} />
                <DetailsRow label='product_bypass_cables_label' value={selected.bypassCables} />
                <DetailsRow label='product_output_cables_label' value={selected.outputCables} />
                <DetailsRow label='product_weight_label' value={selected.weight} />
                <DetailsRow label='product_dimensions_label' value={selected.dimensionsWxDxH} />
                <DetailsRow label='product_heat_dissipation_label' value={selected.heatDissipation} />
              </tbody>
            </table>
        </div>
      </div>

      {/* Services area */}
      <div className="ph2 ph3-m ph4-l vs3 bt bw2 b--grey-30">
        <Heading kind="h3" cls="f3">
          <Translate t="recommendation_screen_services_title" />
        </Heading>
        {!articles.data
          ? <Loading /> 
          : (
            <div className="flex na1 flex-wrap items-stretch">
              {articles.data.map((article, i) => (
                <div className="w-100 w-50-s w-25-m pa1" key={i}>
                  <UseCaseTile
                    title={article.title}
                    body={article.description ?? ''}
                    href={article.link}
                    imgHref={article.image?.url}
                    />
                </div>
              ))}
            </div>
          )
        }
      </div>

      <div id="contact" className="no-print">
        <ContactSection
          countryCode={countryCode}
          locale={locale}
        />
      </div>
    </div>
  )
}

const DetailsRow = ({label, value} : {label: keyof ITranslations, value: number | string | undefined | null}) => 
  <tr className="lh-copy f5 fw5 bb grey-80 bw1 b--grey-20">
    <td className="ph1 pv1"><Translate t={label} fixUnits={true}/></td>
    <td className="f5">{value ?? "-"}</td>
  </tr>

interface IDocumentLink {
  iconCls: string;
  href: string;
  label: string;
}

const DocumentLink: React.FunctionComponent<IDocumentLink> = ({iconCls, href, label}) => {
  const analytics = useAppInsightsContext()
  return (
    <a 
      href={href} 
      className="abb-link-no-underline" 
      target="_blank" 
      rel="noopener noreferrer"
      onClick={_ => analytics.trackPageView({name: 'product detail link', uri: href})}>
      <span className={cx('icon_abb_32 mr2', iconCls)} />
      <span className='f5 fw5'>{label}</span>
    </a>
  )
  }

export default RecommendationScreen
