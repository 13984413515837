import React from 'react';
import cx from 'classnames';
import {OmitAny} from '../utils/typeUtils';

export * from '../designSystem/tachyons';

// TODO: Default styles
export function createDesignSystemComponent<T, P extends object = {}>(
  C: React.ComponentType<P> | string,
): React.StatelessComponent<{cls: T | T[]; extraClassName?: string} & OmitAny<P, 'className'>> {
  // FIXME: External interface works fine, but this expression is confused about the types
  // @ts-ignore
  return ({cls, extraClassName, ...props}) => (
    // @ts-ignore
    <C {...props} className={cx(cls, extraClassName)} />
  );
}
