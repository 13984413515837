import React from 'react';
import cx from 'classnames';
import { Filter } from '../utils/typeUtils';
import { TypeScale, Color } from '../designSystem/tachyons';

interface ICursor {
  fontSize: Filter<TypeScale, 'f1' | 'f2' | 'f3'>;
  color?: Filter<Color, 'red' | 'grey-100'>;
  extraClassName?: string;
}

export const Cursor: React.FunctionComponent<ICursor> = ({
  fontSize,
  color = 'red',
  extraClassName,
}) => <div className={cx('Cursor', fontSize, color, extraClassName)}>—</div>;
