import './SiteLayout.scss'
import logoImage from 'assets/logo.svg'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Translate } from 'features/localization/TranslationsContext'
import { LanguageSelector } from 'siteLayout/LanguageSelector'
import { A2HSPrompt } from 'siteLayout/A2HSPrompt'
import { IBreadcrumb, Breadcrumbs } from 'siteLayout/Breadcrumbs'
import { InfoTab } from 'routes/InfoScreen'
import { useGetAppUrl } from 'app/hooks'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

export const INFO_SCREEN_PATH = 'info'

interface ISiteLayout {
  title?: React.ReactNode
  showLanguageSelector?: boolean
  openInfoLinksInNewTab?: boolean
  breadcrumbs?: IBreadcrumb[]
}

const ABB_URL = "https://new.abb.com"

export const SiteLayout: React.FunctionComponent<ISiteLayout> = ({
  title,
  showLanguageSelector,
  openInfoLinksInNewTab,
  breadcrumbs,
  children,
}) => {
  const infoLinkTarget = openInfoLinksInNewTab ? "_blank" : undefined
  const getInfoUrl = (tab: InfoTab) => `/${INFO_SCREEN_PATH}#${tab}`
  const analytics = useAppInsightsContext()
  
  return (
    <div className="SiteLayout">
      <div className="SiteLayout-wrapper mw8 w-100 flex flex-column min-vh-100 center font-abb-voice bg-grey-00 ">
        <header className="w-100 pv2 ph2 ph3-m ph4-l mb3 flex items-start items-center-m items-center-l no-print">
          <a 
            href={ABB_URL} 
            target="_blank" 
            rel="noopener noreferrer"
            onClick={_ => analytics.trackPageView({name: 'abb link', uri: ABB_URL})}>
            <img alt='abb logo' src={logoImage} className="h2 mr4"/>
          </a>
          
          <div className="grey-40 ttu f5 f4-m flex flex-wrap">
            <Link to={useGetAppUrl('')} className="link grey-40">
              {title || <Translate t="generic_site_title" />}
            </Link>
            {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          </div>

          {showLanguageSelector && (
            <div className="flex-grow-1 flex justify-end">
              <LanguageSelector />
            </div>
          )}
        </header>

        <div className="SiteLayout-Content flex-grow-1">{children}</div>

        <footer className="flex mt6 pv2 ph3 grey-20 bg-grey-60">
          <Link
            to={getInfoUrl('disclaimer')}
            className="link grey-20 underline"
            target={infoLinkTarget}
            >
            <Translate t="generic_copyright_notice" />
          </Link>

          <p className="flex-grow-1 tr">
            <Link
              to={getInfoUrl('versions')}
              className="link grey-20 underline"
              target={infoLinkTarget}
              >
              <Translate t="generic_latest_news_label" />
            </Link>
          </p>
        </footer>

        <A2HSPrompt />
      </div>
    </div>
  )
} 
