import React from 'react'
// @ts-ignore
import { remark } from 'remark'
// @ts-ignore
import remarkHtml from 'remark-html'

// TODO: Type support for `remark`
const remarkProcessor = remark().use(remarkHtml)

// TODO: Add custom link handling - external links should have proper attributes
// (target="_blank" rel="noopener noreferrer") and internal links should use
// client-side routing

interface IMarkdown {
  children?: string
}

export const Markdown: React.FunctionComponent<IMarkdown> = ({children}) => {
  if (children) {
    return (
      <div
        className="Markdown"
        dangerouslySetInnerHTML={{__html: remarkProcessor.processSync(children || '').toString()}}
      />
    );
  } else {
    return null;
  }
};
