import { ButtonLink, Kind, Width } from "components/Button";
import React from "react";
import { useLocation } from "react-router-dom"
import { ITranslations, Translate } from "features/localization/TranslationsContext";
import { useGetAppUrl } from "app/hooks";


export const BackButton = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="pa2">
      <ButtonLink 
        kind="discreet-black" 
        // remove the last segment from the path
        to={path.substring(0, path.lastIndexOf('/')) + location.search}>
        <Translate t="generic_label_previous" />
      </ButtonLink>
    </div>
  )
}

export const ForwardButton = ({ value, kind, width, label, className }: { value?: string, kind?: Kind, width?: Width, label?: keyof ITranslations, className?: string }) => 
  <div className={className ?? "pa2"}>
    <ButtonLink
      kind={kind ?? "primary-red"}
      width={width}
      to={useGetAppUrl(value ?? '', true)}
      disabled={!value}
    >
      <Translate t={label ?? "generic_label_next"} />
    </ButtonLink>
  </div>

export const RouteButtons = ({ value }: { value?: string }) =>
  <div className="na2 flex flex-row justify-end">
    <BackButton />
    <ForwardButton value={value} />
  </div>