import './Navigator.scss';
import React from 'react';
import cx from 'classnames';
import {Link, LinkProps} from 'react-router-dom';

export type NavigatorItemStatus = 'visited' | 'active' | 'future';

export interface INavigatorItem {
  label: string | React.ReactNode;
  url: string;
  status: NavigatorItemStatus;
}

interface INavigator {
  items: INavigatorItem[];
}

// NOTE: Be sure to pick out any props that we pass to `Wrapper` below
const NoLink: React.FunctionComponent<LinkProps> = ({...props}) => <div {...(props as any)} />;

export const Navigator: React.FunctionComponent<INavigator> = ({items}) => {
  const numItems = items.length;

  return (
    <div className="Navigator grey-30 ph2 ph3-m ph4-l">
      <div className="nh1 nh3-m flex flex-row justify-between">
        {items.map((item, i) => {
          const next = i + 1 < numItems ? items[i + 1] : null;

          const Wrapper = item.status !== 'future' ? Link : NoLink;
          return (
            <Wrapper
              key={i}
              to={item.url}
              className={cx(
                'Navigator-Item relative flex flex-column items-center z-1 no-underline',
                // Color
                item.status === 'active'
                  ? 'grey-90'
                  : item.status === 'visited'
                  ? 'grey-60'
                  : 'grey-30',
                // State management for lines in between the dots
                item.status !== 'future' && 'Navigator-Item--is-visited',
                item.status === 'active' && 'Navigator-Item--is-active',
                next && next.status !== 'future' && 'Navigator-Item--next-is-visited',
              )}
            >
              {/* Circle */}
              <div className="pv3 ph1 ph3-m w-100">
                <div
                  className={cx(
                    'w1 h1 center br-100',
                    item.status === 'active'
                      ? 'bg-red'
                      : item.status === 'visited'
                      ? 'bg-grey-60'
                      : 'bg-grey-30',
                  )}
                />
              </div>

              {/* TODO: Disabled link when !isBeforeCurrent */}
              <div className="Navigator-Label dn dib-m tc">{item.label}</div>
            </Wrapper>
          );
        })}
      </div>
    </div>
  );
};
