import './Checkbox.scss';
import React from 'react';
import RcCheckbox from 'rc-checkbox';

interface ICheckbox {
  name?: string;
  id?: string;
  value: string;
  checked?: boolean;
  defaultChecked?: boolean;
  extraClassName?: string;
  onChange?: (e: Event, checked: boolean) => any;
}

export const Checkbox: React.FunctionComponent<ICheckbox> = ({extraClassName, ...props}) => (
  <RcCheckbox {...(props as any)} className={extraClassName} />
);
