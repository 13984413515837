import React from 'react';
import { Checkbox } from '../components/Checkbox';

interface IRadioButton {
  value: string;
}

interface IRadioButtonContainer {
  name: string;
  value?: string;
  onChange?: (value: string) => any;
  children: Array<React.ReactElement<IRadioButton> | undefined>;
}

interface IRadioButtonImpl extends IRadioButton {
  name?: string;
  onChange: (ev: Event, checked: boolean) => any;
}

export const RadioButton: React.FunctionComponent<IRadioButton> = () => null;

const RadioButtonImpl: React.FunctionComponent<IRadioButtonImpl> = ({children, ...props}) => (
  <label className="RadioButton flex w-100 pv1">
    <Checkbox extraClassName="pa1 pr2" {...props} />
    {children}
  </label>
);

export const RadioButtonContainer: React.FunctionComponent<IRadioButtonContainer> = ({
  children,
  name,
  value,
  onChange,
}) => (
  <div className="RadioButtonContainer flex flex-column items">
    {React.Children.map(
      children as any,
      child =>
        child && (
          <RadioButtonImpl
            {...child.props}
            name={name}
            checked={value === child.props.value}
            onChange={() => onChange && onChange(child.props.value)}
          />
        ),
    )}
  </div>
);
