import './PowerScreen.scss';
import descriptionImage from 'assets/img/power-description.jpg'
import React, { useEffect, useState } from 'react'
import { CursorHeading } from 'components/CursorHeading'
import { Translate } from 'features/localization/TranslationsContext'
import { Loading} from 'components/Loading'
import { ProductStubViewModel, useGetApiArticlesQuery } from 'features/gstApi/gstApi'
import { RouteButtons } from 'routes/RouteButtons'
import { ExtraContentArea } from 'components/ExtraContentArea'
import { TopicHighlight } from 'components/TopicHighlight'
import { UseCaseTile, UseCaseTiles } from 'components/UseCaseTiles'
import { useAppSelector } from 'app/hooks'
import { NumericInput, Slider } from '@abb/abb-common-ux-react'
import { ErrorLabel } from 'components/ErrorLabel';

// Should be a character that doesn't appear in the values and will not get url escaped
export const VALUE_SEPARATOR = '*'

export const getPowerAndRuntimeSelection = (value? : string) => {
  const split = value?.split(VALUE_SEPARATOR)
  const values = split?.map(str => str ? Number.parseFloat(str) : undefined)
  return {
    power: {
      min: values?.at(0),
      max: values?.at(1)
    },
    runtime: values?.at(2)
  }
}

export const getPowerAndRuntimeValues = (products: ProductStubViewModel[], value? : string) => {
  const values = getPowerAndRuntimeSelection(value)
  return {
    power: {
      min: values.power.min || 0,
      max: 
        values.power.max
        || Math.max.apply(Math, products.map(p => p.ratedPower))
    },
    runtime: values.runtime || 0
  }
}

const PowerScreen = ({ products, selected }: { products: ProductStubViewModel[], selected?: string }) => {
  const { locale, translations } = useAppSelector(state => state.localization)

  const initial = getPowerAndRuntimeValues(products, selected)

  const [power, setPower] = useState<{min: number, max: number}>(initial.power)
  const [prevPower, setPrevPower] = useState<{min: number, max: number}>(initial.power)
  const [powerError, setPowerError] = useState<string>()

  const minPower = Math.min.apply(Math, products.map(p => p.ratedPower))
  const maxPower = Math.max.apply(Math, products.map(p => p.ratedPower))
  const minRange = (maxPower - minPower) * 0.05;

  const [runtime, setRuntime] = useState<number | undefined>(initial.runtime)
  const [runtimeError, setRuntimeError] = useState<string>()
  const [maxRuntime, setMaxRuntime] = useState<number>(0)


  useEffect(() => {
    // Don't allow moving sliders too close to each other as the user won't be able to move them out if they overlap
    // Were the sliders moved too close to gether?
    if (power.max - power.min < minRange) {
      // Moved min slider
      if (prevPower.min != power.min)
        setPower({min: power.max - minRange, max: power.max})
      else if (prevPower.max != power.max)
        setPower({min: power.min, max: power.min + minRange})
    }

    setPrevPower(power)

    const loadMatchProducts = 
      products
        .filter(p => power.min <= p.ratedPower && p.ratedPower <= power.max)

    setPowerError(
      loadMatchProducts.length == 0 
        ? translations.power_screen_no_products_for_given_load
        : undefined
    )

    const maxRuntime =
      loadMatchProducts
        .filter(p => p.batteryRuntime)
        .map(p => p.batteryRuntime ?? 0)
        .sort((a, b) => b - a)
        .at(0) ?? 0

    setMaxRuntime(maxRuntime)   
  }, [power, products])
  
  useEffect(() => {
    setRuntimeError(
      runtime !== undefined
      ? runtime > maxRuntime
        ? translations.power_screen_runtime_too_high.replace('{1}', maxRuntime.toString())
        : undefined
      : translations.power_screen_runtime_missing
    )
  }, [runtime, maxRuntime, products])

  const defaultArticles = 
    useGetApiArticlesQuery(
      { type: "PowerAndRuntimeFurtherReading", locale: locale }
    )
  
  const articles = 
    defaultArticles.isFetching
      ? undefined
      : defaultArticles.data;

  return (
    <div className="PowerScreen vs4 ph2 ph3-m ph4-l">
      <CursorHeading kind="h2" cls={['f2']}>
        <Translate t="power_screen_title" />
      </CursorHeading>

      <div className="flex flex-column flex-row-m flex-row-l na2 na3-l">
        <div className="pa2 pa3-l w-100 flex flex-column">
          <p className="pb2 grey-60"> 
            <Translate t="power_screen_load_label" />
          </p>
          <Slider
            type="double"
            highlight="middle"
            min={minPower}
            max={maxPower}
            values={[power.min, power.max]}
            onChange={values => 
              Array.isArray(values)
                && setPower({min: values[0], max: values[1]})
            } 
          />         
          {/* visibility: hidden doesn't fully hide the ErrorLabel so we use minHeight to prevent the layout form changing when the error appears */}     
          <div style={{marginLeft: '8px', marginTop: '-12px', color: 'red', minHeight: '24px'}}>
            {powerError && 
              <ErrorLabel children={powerError}/>
            }        
          </div>
        </div>

        <div className="pa2 pa3-l w-100">
        {
          maxRuntime == 0
          ? undefined
          : <>
              <p className="pb2 grey-60">
                <Translate t="power_screen_runtime_label" />
              </p>          
              <NumericInput
                min={0}
                step={1}
                type='compact'
                value={runtime}
                onChange={setRuntime}
                validationMessage={runtimeError}
                showIndicatorsWhenFocused={true}
                validationBar={runtimeError ? 'error' : false}
                validationIcon={runtimeError ? 'error' : false}
              />
            </>
      }
        </div>
      </div>

      <RouteButtons 
        value={
          power && !powerError && (maxRuntime === 0 || !runtimeError)
            ? power.min  + VALUE_SEPARATOR + power.max + VALUE_SEPARATOR + (maxRuntime === 0 ? 0 : runtime) 
            : undefined
        }
      />
          
      <ExtraContentArea
        primaryArea={
          <TopicHighlight
            title={<Translate t="power_screen_description_title" />}
            imgHref={ descriptionImage }
            body={<Translate t="power_screen_description_lede" />}
          />
        }
        secondaryArea={
          !articles
          ? <Loading height="h5" extraClassName="mt2" />
          : articles.length > 0 
            ? <UseCaseTiles title={translations.generic_label_further_reading}>
              {
                articles.slice(0, 2).map((article, i) => (
                  <UseCaseTile
                    key={i}
                    imgHref={article.image?.url ?? ''}
                    title={article.title}
                    body={article.description ?? ''}
                    href={article.link}
                  />
                ))
              }
              </UseCaseTiles>
            : <></>
        }
      />
    </div>
  )
}

export default PowerScreen
