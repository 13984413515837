import mapImage from 'assets/img/map-dots.jpg';
import { Box, Flexbox } from 'components/Box'
import { CursorHeading } from 'components/CursorHeading'
import Dropdown from 'components/Dropdown'
import { Paragraph } from 'components/Paragraph'
import { Image } from 'components/Image'
import { CountryViewModel } from 'features/gstApi/gstApi'
import { Translate, TranslationsContext } from 'features/localization/TranslationsContext'
import { useContext, useState } from 'react'
import { ForwardButton } from './RouteButtons';

/**
 * View that let's the user select a country from the given {@link countries} and appends it's {@link CountryViewModel.code} to the url
 * @param countries Countries from which user can choose
 * @param selected {@link CountryViewModel.code} of the initially selected country (if any)
 */
const CountryScreen = ({ countries, selected }: { countries: CountryViewModel[], selected?: string }) => {
  const translations = useContext(TranslationsContext);
  const [country, setCountry] = useState(selected)

  return (
    <div className="CountryScreen vs4">
    <Box cls={['ph2', 'ph3-m', 'ph4-l']}>
      <CursorHeading kind="h2" cls={['f2']}>
        <Translate t="country_screen_title" />
      </CursorHeading>

      <Paragraph cls={['grey-90']}>
        <Translate t="country_screen_lede" />
      </Paragraph>

      <Flexbox cls={['flex', 'flex-column', 'flex-row-m']} extraClassName="mt4">
        <Box cls={['w-100', 'w-60-m']}>
          <Image aspectRatio="12x5" className="w-100" src={mapImage} />
        </Box>
        <Box cls={['w-100', 'w-40-m', 'pl0', 'pl4-m']}>
          <Dropdown
            className="db mb3"
            label={translations.generic_label_country}
            disabled={!countries.length}
            onChange={setCountry}
            options={
              countries.map(c => ({
                value: c.code,
                label: c.name,
              }))}
            placeholder={translations.country_screen_select_country_placeholder}
            value={country}
          />
          <div className="tr">
            <ForwardButton value={country} />
          </div>
        </Box>
      </Flexbox>
    </Box>
  </div>
  )
}

export default CountryScreen;