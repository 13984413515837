import './App.scss';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import StartScreen from 'routes/StartScreen';
import { INFO_SCREEN_PATH, SiteLayout } from 'siteLayout/SiteLayout';
import { SelectorLayout } from 'siteLayout/SelectorLayout';
import { Translate, TranslationsContext } from 'features/localization/TranslationsContext';
import { useAppDispatch, useAppSelector, useGetAppUrl } from 'app/hooks';
import { initialize } from "features/selector/selectorSlice";
import SelectorStepHost from 'features/selector/SelectorStepHost';
import STEPS from 'features/selector/Steps';
import { selectTranslations, update as updateLocale } from 'features/localization/localizationSlice';
import InfoScreen from 'routes/InfoScreen';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { aiReactPlugin, usePageAnalytics } from 'features/telemetry/telemetryService';

export const SELECTOR_PATH = 'select'

function App() {
  const dispatch = useAppDispatch()
  const translations = useAppSelector(selectTranslations)

  const queryString = useLocation().search
  
  useEffect(() => {
    dispatch(updateLocale(queryString))
  }, [queryString])

  useEffect(() => {
    dispatch(initialize(STEPS.length))
  }, [])

  return (
    <TranslationsContext.Provider value={translations}>
      <AppInsightsContext.Provider value={aiReactPlugin}>
        <AppInsightsErrorBoundary 
          onError={() =>
            <div className='tc mt6 fw3'>
              <Translate t='generic_error_occured_label' />
            </div>} 
          appInsights={aiReactPlugin}
        >
          <Routes>
            <Route path="/" element={ <StartPage/> }  />
            <Route path={INFO_SCREEN_PATH} element={ <SiteLayout><InfoScreen/></SiteLayout> } />
            <Route path={SELECTOR_PATH} element={ <SelectorLayout steps={STEPS}/> }>
              {STEPS.map((_, i) => 
                <Route 
                  key={i}
                  path={':a/'.repeat(i)} 
                  element={
                    <SelectorStepHost 
                      // 'key' really is CRUCIAL here!
                      key={i} index={i} steps={STEPS}
                    />}
                />
              )}
            </Route>
          </Routes>
        </AppInsightsErrorBoundary>
      </AppInsightsContext.Provider>
    </TranslationsContext.Provider>
  )
}

const StartPage = () => {
  const dispatch = useAppDispatch()
  
  usePageAnalytics('start')

  useEffect(() => {
    // Reset/initialize state
    dispatch(initialize(STEPS.length))
  }, [])

  return (
    <SiteLayout showLanguageSelector openInfoLinksInNewTab>
      <StartScreen nextUrl={useGetAppUrl(SELECTOR_PATH, true)} />
    </SiteLayout>
  )
}

export default App;
