import './CategorySelection.scss';
import React from 'react';
import cx from 'classnames';
import {ReactSVG} from 'react-svg';
import {Spinner} from '@abb/abb-common-ux-react/components/Spinner/Spinner';

export interface ICategory {
  title: string;
  iconUrl: string;
}

interface ICategorySelection {
  categories: ICategory[];
  selectedIndex?: number;
  onSelect?: (selectedIndex: number) => void;
}

export const CategorySelection: React.FunctionComponent<ICategorySelection> = ({
  categories,
  selectedIndex,
  onSelect,
}) => {
  return (
    <div className="CategorySelection vs4">
      {/* Icons */}
      <ul className="flex flex-row flex-wrap grey-60 mh4-s mh3-m">
        {categories.map(({title: name, iconUrl}, index) => (
          <li
            key={index}
            className={cx(
              'CategorySelection-Item pa2-s pa3-m pa3-l pointer tc',
              selectedIndex === index && 'CategorySelection-Item--selected',
            )}
          >
            <button
              onClick={() => onSelect && onSelect(index)}
              className="tc bg-transparent bn f4 w4"
            >
              <ReactSVG
                src={iconUrl}
                loading={() => <Spinner color="red" sizeClass="medium" />}
                
                beforeInjection={svg => {
                  svg.setAttribute('style', 'height: 48px')
                }}
              />
              {name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
