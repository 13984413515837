import './Loading.scss';
import React from 'react';
import cx from 'classnames';
import {Height} from '../designSystem/tachyons';

interface ILoading {
  isTopLevel?: boolean;
  height?: Height | Height[];
  extraClassName?: string;
}

export const Loading: React.FunctionComponent<ILoading> = ({
  isTopLevel,
  height = 'h-auto',
  extraClassName,
  children,
}) => (
  <div className={cx('Loading', isTopLevel && 'Loading--toplevel', extraClassName, height)}>
    {children}
  </div>
);
