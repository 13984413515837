import React from 'react';
import {
  createDesignSystemComponent,
  Display,
  Position,
  Offset,
  Width,
  MaxWidth,
  Height,
  MinHeight,
  Margin,
  Padding,
  Flex,
  Overflow,
} from '../designSystem/designSystem';

type BoxClasses =
  | Display
  | Position
  | Offset
  | Width
  | MaxWidth
  | Height
  | MinHeight
  | Margin
  | Padding
  | Overflow;

export const Box = createDesignSystemComponent<BoxClasses, React.HTMLAttributes<HTMLDivElement>>(
  'div',
);

export const Flexbox = createDesignSystemComponent<
  Flex | Exclude<BoxClasses, Display>,
  React.HTMLAttributes<HTMLDivElement>
>('div');
