import React from 'react';
import cx from 'classnames';
import {Heading} from 'components/Heading';
import {Image} from 'components/Image';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

export interface IUseCaseTiles {
  title?: string;
  children?: Array<React.ReactElement<IUseCaseTile> | boolean | null | undefined>;
}

interface IUseCaseTile {
  imgHref?: string;
  title?: string;
  body: string;
  href: string;
}

export const UseCaseTile: React.FunctionComponent<IUseCaseTile> = ({
  imgHref,
  title,
  body,
  href,
}) => {
  const analytics = useAppInsightsContext()

  return (
    <a
      href={href}
      className="UseCaseTile db link bg-grey-10 w-100 h-100"
      target="_blank"
      rel="noopener noreferrer"
      onClick={_ => analytics.trackPageView({name: 'article link', uri: href})}
    >
      <Image src={imgHref} className="w-100 bg-blue-10" aspectRatio="2x1" />
      <div className="pa2 min-h3 grey-90 f5 lh-title">
        {title && <h3 className="f4 fw5">{title}</h3>}
        <p>{body}</p>
      </div>
    </a>
  )
}

export const UseCaseTiles: React.FunctionComponent<IUseCaseTiles> = ({title, children}) => (
  <div className={cx('UseCaseTiles', !!title && 'nt3')}>
    {title && (
      <Heading kind="h3" cls={['f3']}>
        {title}
      </Heading>
    )}

    <div className="na1 flex flex-column flex-row-s items-stretch">
      {React.Children.map(children, useCaseTile => (
        <div className="pa1 w-100 flex">{useCaseTile}</div>
      ))}
    </div>
  </div>
);
