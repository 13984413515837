import React, {useEffect, useState, useCallback} from 'react';
import {Button} from 'components/Button';
import {Translate} from 'features/localization/TranslationsContext';

export const A2HSPrompt: React.FunctionComponent<{}> = () => {
  const [beforePromptEvent, setBeforePromptEvent] = useState<Event>();
  const onAddToHomeScreen = useCallback(() => {
    // @ts-ignore - hack against non-existing types for BeforeInstallPromptEvent
    beforePromptEvent.prompt();
    // @ts-ignore
    beforePromptEvent.userChoice.then((/* choiceResult */) => {
      // Depending on which documentation you read, we could read the following
      // https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
      // choiceResult === 'accepted' // MDN
      // OR
      // https://developers.google.com/web/fundamentals/app-install-banners/
      // choiceResult.outcome === 'accepted'

      // Anyhow we ignore both and just hide the dialog
      setBeforePromptEvent(undefined);
    });
  }, [beforePromptEvent, setBeforePromptEvent]);
  const onCancel = useCallback(() => setBeforePromptEvent(undefined), [setBeforePromptEvent]);

  // beforeinstallprompt listener
  useEffect(() => {
    function listener(e: Event) {
      // Prevent Chrome 76 and later from showing the mini-infobar
      e.preventDefault();

      setBeforePromptEvent(e);
    }

    window.addEventListener('beforeinstallprompt', listener);
    return () => window.removeEventListener('beforeinstallprompt', listener);
  }, []);

  return beforePromptEvent ? (
    <div className="A2HPrompt z-1 fixed bottom-0 left-0 right-0 shadow-1 pa3 flex justify-center items-center flex-wrap shadow-1 bg-grey-00">
      <p className="ma3 f5 lh-copy grey-90 fw3">
        <Translate t="add_to_home_screen_lede" />
      </p>
      <div>
        <Button kind="discreet-black" className="mh3" onClick={onCancel}>
          <Translate t="add_to_home_screen_cancel_label" />
        </Button>
        <Button kind="primary-red" className="mh3" onClick={onAddToHomeScreen}>
          <Translate t="add_to_home_screen_add_label" />
        </Button>
      </div>
    </div>
  ) : null;
};
