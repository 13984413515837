import React from 'react';
import {TooltipContainer, Tooltip} from 'components/Tooltip';

export const InfoButton: React.FunctionComponent = ({children}) => (
  <TooltipContainer>
    {(isTooltipOpen, toggleTooltip) => (
      <>
        <button className="pa1 bn bg-transparent pointer" onClick={toggleTooltip}>
          <span className="icon_abb_32 icon-abb_help-circle-2_32 f4 grey-60 hover-grey-90" />
        </button>

        <Tooltip isOpen={isTooltipOpen} position="right" extraClassName="top-2">
          {children}
        </Tooltip>
      </>
    )}
  </TooltipContainer>
);
