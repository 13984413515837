import { ISelectorStep, StepId } from './SelectorStep'
import { SelectorStepState } from './selectorSlice'

export class StepStatesMap {
  readonly pages: readonly ISelectorStep<any, any>[]
  readonly states: SelectorStepState[]

  constructor(pages: readonly ISelectorStep<any, any>[], states: SelectorStepState[]) {
    this.pages = pages
    this.states = states
  }

  private get(step: StepId) {
    for (let i = 0; i < this.pages.length; i++) {
      const page = this.pages[i]
      if (page.id === step) {
        const state = this.states[i]
        if (state !== undefined)
          return state
        else
          break
      }
    }

    throw new Error('State for step ' + step + ' not found! Are you trying to get the state of a further step?')
  }

  value(step: StepId) {
    return this.get(step).value
  }

  products(step: StepId) {
    const products = this.get(step).products
    // return shallow clone
    return products && [ ...products ]
  }
}
