import React from 'react';
import cx from 'classnames';
import {Filter} from '../utils/typeUtils';
import {createDesignSystemComponent, TypeScale} from '../designSystem/designSystem';

interface IHeading extends React.HTMLAttributes<HTMLHeadingElement> {
  kind?: 'h1' | 'h2' | 'h3' | 'h4';
}

export const Heading = createDesignSystemComponent<Filter<TypeScale, 'f1' | 'f2' | 'f3'>, IHeading>(
  ({kind = 'h2', className, ...props}) => {
    const C = kind as any;
    return <C {...props} className={cx(className, 'lh-title mv3')} />;
  },
);
