import React from 'react';
import cx from 'classnames';
import {LinkProps, Link} from 'react-router-dom';

export type Kind = 'primary-red' | 'primary-black' | 'ghost' | 'discreet-black' | 'discreet-black-small'
export type Width = '1' | '2' | '3' | '4' | '5' | '6'

interface IButton {
  // Kind-types are from ABB guidelines, @see https://commonux.abb.com/#/component/Button
  // Personally, I wouldn't have named these with -red and -black which to
  // me are clearly implementation specific. I'd rather have primary, primary-alt,
  // secondary, etc.. but let's harmonize with the guidelines
  kind?: Kind;
  width?: Width;
  disabled?: boolean;
}

interface IButtonProvider extends IButton {
  className?: string;
  children: (className: string) => React.ReactElement<any>;
}

interface IButtonLink {
  isExternal?: boolean;
}

function Styles(kind: Kind, disabled?: boolean) {
  switch (kind) {
    case 'primary-red':           return 'ttu bn ' + (disabled ? 'bg-grey-20 grey-60' : 'bg-red grey-00 hover-bg-red-dark')
    case 'primary-black':         return 'ttu bn ' + (disabled ? 'bg-grey-20 grey-60' : 'bg-grey-90 grey-00 hover-bg-grey-80')
    case 'ghost':                 return 'ttu ba fw5 ' + (disabled ? 'bw1 ma05 b--grey-40 bg-grey-20 grey-60' : 'bw2 b--grey-90 bg-grey-00 grey-90 hover-bg-grey-10')
    case 'discreet-black':        return 'ttu bn fw5 ' + (disabled ? 'bg-grey-20 grey-60' : 'bg-transparent grey-60 hover-grey-90')
    case 'discreet-black-small':  return 'bn ' + (disabled ? 'bg-grey-20 grey-60' : 'bg-transparent grey-60 hover-grey-90')
  }
}

export const ButtonProvider: React.FC<IButtonProvider> = ({
  kind = 'primary-red',
  width,
  className,
  disabled,
  children,
}) => {
  const cls = cx(
    className,
    'pv2 br-pill font-abb-voice f4 lh-solid tc',
    'ph' + (width ?? '4'),
    disabled ? 'cursor-default' : 'pointer',
    Styles(kind, disabled), 
  );

  return children(cls);
};

export const Button: React.FC<
  React.HTMLAttributes<HTMLButtonElement> & IButton
> = ({kind = 'primary-red', width, className, disabled, ...props}) => (
  <ButtonProvider kind={kind as any} width={width} className={className} disabled={disabled}>
    {cls => <button {...props} className={cls} />}
  </ButtonProvider>
);

export const ButtonLink: React.FC<LinkProps & IButton & IButtonLink> = ({
  kind = 'primary-red',
  width,
  isExternal,
  className,
  disabled,
  onClick,
  ...props
}) => (
  <ButtonProvider
    kind={kind as any}
    width={width}
    className={cx('no-underline dib', className)}
    disabled={disabled}
  >
    {cls => {
      if (isExternal) {
        return (
          <a
            {...props}
            href={`${props.to}`}
            onClick={disabled ? ev => ev.preventDefault() : undefined}
            className={cls}
            target="_blank"
            rel="noopener noreferrer"
          />
        );
      } else {
        return (
          <Link
            {...props}
            onClick={ev => {
              if (disabled) {
                ev.preventDefault();
              } else if (onClick) {
                return onClick(ev);
              }
            }}
            className={cls}
          />
        );
      }
    }}
  </ButtonProvider>
);
