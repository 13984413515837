import React, { useState } from 'react';
import cx from 'classnames';
import { Locale, selectLocale, QUERY_PARAMETER, Locales } from 'features/localization/localizationSlice';
import { useAppSelector } from 'app/hooks';
import { Link } from 'react-router-dom';

export function getLabel(locale: Locale) {
  switch (locale) {
    case 'en-US': return 'English'
    case 'de-DE': return 'Deutsch - German'
    case 'es-ES': return 'Español - Spanish'
    case 'fr-FR': return 'Française - French'
    case 'it-IT': return 'Italiano - Italian'
  }
}

export const LanguageSelector: React.FunctionComponent<{extraClassName?: string}> = ({
  extraClassName,
}) => {
  const locale = useAppSelector(selectLocale)
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cx('LanguageSelector relative w5-m flex justify-end', extraClassName)}>
      <button
        className="bn bg-transparent pv2 ph1 ph3-m f5 f4-s font-abb-voice pointer flex grey-60"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="icon_abb_32 icon-abb_globe_32 f3 ph1" />
        <span className="dn db-s ph1">{getLabel(locale)}</span>
        <span className="ph1">{isOpen ? '▲' : '▼'}</span>
      </button>

      <ul
        className={cx(
          'absolute right-0 top-100 pv2 w5 w-auto-m z-2 tl bg-grey-00 shadow-1',
          isOpen ? 'db' : 'dn',
        )}
      >
        {Locales.map(l => (
          <li key={l}>
            <Link
              className={cx(
                'db pv2 ph3 grey-60 underline-hover',
                locale === l ? 'fw7 underline' : 'no-underline',
              )}
              onClick={() => setIsOpen(false)}
              to={`?${QUERY_PARAMETER}=${l}`}
              replace
            >
              {getLabel(l)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
